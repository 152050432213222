uib-accordion.custom {
    font-family: localSegoeUI, "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
    .panel-group {
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        .panel-default {
            border: none;
            .ch-panel-heading {
                border-top: 2px solid $primary-color;
                border-left: none;
                border-right: none;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                background-color: white;
                min-height: 110px;
                padding-left: 64px;
                padding-right: 64px;
                padding-top: 39px;

                .ch-panel-title {
                    color: $primary-color;
                    font-weight: bold;
                    font-size: 28px;
                    a {
                        text-decoration: none;
                    }
                    i {
                        font-weight: normal;
                        font-size: 20px;
                    }
                }
            }

            & > .panel-heading + .panel-collapse > .panel-body {
                border-top: none;
            }
        }

        .card-divider {
            line-height: 64px;
            width: 95%;
            display: flex;
            align-items: center;
            justify-content: center;

            > span {
                margin-right: 5px;
            }
            &:after {
                background-color: $newreferral-line-color;
                height: 1px;
                flex: 1;
                content: '';
            }
            &-title {
                color: $newreferral-title-color;
                font-size: 10px;
                text-transform: uppercase;
            }
        }

        .card-lineheight {
            line-height: 32px;
        }

        .card-label {
            color: $select-title-color;
            font-size: 16px;
            margin-bottom: 0;
        }

        .card-error {
            font-size: 10px;
            line-height: 10px;
        }

        .card-input {
            input {
                font-size: 16px;
                width: $select-width-default;
                border: none;
                color: $select-dropdown-color;
                padding-left: 8px;
                text-overflow: ellipsis;
            }
        }

        .card-checkbox {
            input {
                margin-left: 10px;
            }
        }
    }
}

