.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(sprite.svg);

    &.half {
        transform: scale(.5);
    }
}
.icon-16 {
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(sprite16.svg);
}
.icon-ch-clear-filter-sm {
    width: 14px;
    height: 14px;
    background-position: 0 0;
}
.icon-ch-filter {
  width: 16px;
  height: 16px;
  background-position: -48px 0;
}
.icon-ch-arrow_down_blue {
  width: 22px;
  height: 16px;
  background-position: -96px 0;
}
.icon-ch-arrow_right_blue {
  width: 10px;
  height: 16px;
  background-position: -192px 0;
}
.icon-ch-ach-request {
  width: 40px;
  height: 32px;
  background-position: -240px 0;
}
.icon-ch-ach-request_blue {
  width: 40px;
  height: 32px;
  background-position: -288px 0;
}
.icon-ch-annual_funding {
  width: 41px;
  height: 32px;
  background-position: -336px 0;
}
.icon-ch-annual_funding_blue {
  width: 41px;
  height: 32px;
  background-position: -432px 0;
}
.icon-ch-banking {
  width: 29px;
  height: 32px;
  background-position: -528px 0;
}
.icon-ch-banking_blue {
  width: 29px;
  height: 32px;
  background-position: -576px 0;
}
.icon-ch-funding_events {
  width: 29px;
  height: 32px;
  background-position: -528px 0;
}
.icon-ch-funding_events_blue {
  width: 29px;
  height: 32px;
  background-position: -576px 0;
}
.icon-ch-benefits {
  width: 30px;
  height: 32px;
  background-position: -624px 0;
}
.icon-ch-benefits_blue {
  width: 30px;
  height: 32px;
  background-position: -672px 0;
}
.icon-ch-carequotes {
  width: 20px;
  height: 32px;
  background-position: -720px 0;
}
.icon-ch-carequotes_blue {
  width: 20px;
  height: 32px;
  background-position: 0 -48px;
}
.icon-ch-claim_history {
  width: 29px;
  height: 32px;
  background-position: -48px -48px;
}
.icon-ch-claim_history_blue {
  width: 29px;
  height: 32px;
  background-position: -96px -48px;
}
.icon-ch-contacts {
  width: 50px;
  height: 32px;
  background-position: -144px -48px;
}
.icon-ch-contacts_blue {
  width: 50px;
  height: 32px;
  background-position: -240px -48px;
}
.icon-ch-documents {
  width: 32px;
  height: 32px;
  background-position: -336px -48px;
}
.icon-ch-documents_blue {
  width: 32px;
  height: 32px;
  background-position: -384px -48px;
}
.icon-ch-documents_blue1 {
  width: 23px;
  height: 32px;
  background-position: -432px -48px;
}
.icon-ch-exclamation {
  width: 32px;
  height: 32px;
  background-position: -480px -48px;
}
.icon-ch-flag {
  width: 25px;
  height: 32px;
  background-position: -528px -48px;
}
.icon-ch-flag_blue {
  width: 25px;
  height: 32px;
  background-position: -576px -48px;
}
.icon-ch-forms {
  width: 23px;
  height: 32px;
  background-position: -624px -48px;
}
.icon-ch-forms_blue {
  width: 23px;
  height: 32px;
  background-position: -672px -48px;
}
.icon-ch-new_referral {
  width: 44px;
  height: 32px;
  background-position: 0 -96px;
}
.icon-ch-notes {
  width: 29px;
  height: 32px;
  background-position: -96px -96px;
}
.icon-ch-notes_blue {
  width: 29px;
  height: 32px;
  background-position: -144px -96px;
}
.icon-ch-profile {
  width: 30px;
  height: 32px;
  background-position: -192px -96px;
}
.icon-ch-profile_blue {
  width: 30px;
  height: 32px;
  background-position: -240px -96px;
}
.icon-ch-search {
  width: 30px;
  height: 32px;
  background-position: -288px -96px;
}
.icon-ch-stop_payments {
  width: 29px;
  height: 32px;
  background-position: -336px -96px;
}
.icon-ch-stop_payments_blue {
  width: 29px;
  height: 32px;
  background-position: -384px -96px;
}
.icon-ch-circle-plus {
    width: 32px;
    height: 32px;
    background-position: -432px -96px;
}
.icon-ch-x-circle {
    width: 32px;
    height: 32px;
    background-position: -480px -96px;
}
.icon-ch-circle-minus {
    width: 32px;
    height: 32px;
    background-position: -528px -96px;
}
.icon-ch-circle-check {
    width: 32px;
    height: 32px;
    background-position: -576px -96px;
}
.icon-ch-close {
    width: 32px;
    height: 32px;
    background-position: -624px -96px;
}
.icon-ch-delete {
    width: 32px;
    height: 32px;
    background-position: -672px -96px;
}
.icon-ch-check {
    width: 32px;
    height: 32px;
    background-position: -720px -96px;
}
.icon-ch-download-1 {
    width: 32px;
    height: 32px;
    background-position: 0 -144px;
}
.icon-ch-checkbox-checked {
    width: 32px;
    height: 32px;
    background-position: -48px -144px;
}
.icon-ch-checkbox-uncheck {
    width: 32px;
    height: 32px;
    background-position: -96px -144px;
}
.icon-16-ch-circle-plus {
    width: 16px;
    height: 16px;
    background-position: 0 0;
}
.icon-16-ch-x-circle {
    width: 16px;
    height: 16px;
    background-position: -32px 0;
}
.icon-16-ch-circle-minus {
    width: 16px;
    height: 16px;
    background-position: -64px 0;
}
.icon-16-ch-circle-check {
    width: 16px;
    height: 16px;
    background-position: -96px 0;
}
.icon-16-ch-close {
    width: 16px;
    height: 16px;
    background-position: -128px 0;
}
.icon-16-ch-delete {
    width: 16px;
    height: 16px;
    background-position: -160px 0;
}
.icon-16-ch-check {
    width: 16px;
    height: 16px;
    background-position: -192px 0;
}
.icon-16-ch-download-1 {
    width: 16px;
    height: 16px;
    background-position: -224px 0;
}
.icon-16-ch-checkbox-checked {
    width: 16px;
    height: 16px;
    background-position: -256px 0;
}
.icon-16-ch-checkbox-uncheck {
    width: 16px;
    height: 16px;
    background-position: -288px 0;
}