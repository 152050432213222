@mixin link-reset() {
    a {
        color: inherit;
        text-decoration: none;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

.nav-ch {
    $root: &;

    @include link-reset();

    ul {
        @include clear-menu();
    }

    > ul {
        text-align: center;
    }

    & &__item {
        text-align: left;
        position: relative;
        display: inline-block;
        border-bottom: 4px solid transparent;
        cursor: pointer;

        &:hover {
            > a {
                color: $dulled_white;
            }

            #{$root}__dropdown {
                display: block;
            }
        }
    }

    & &__dropdown {
        position: absolute;
        display: none;
        min-width: 250px;
        padding-bottom: 20px;
        top: calc(100% + 4px);
        left: 0;
        z-index: 1001;
        margin: 0;
        background-color: white;
        color: black;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        overflow-y: auto;
        overflow-x: hidden;

        ul {
            min-width: 250px;
            > li {
                &:hover {
                    background-color: #F0F0F0;
                    opacity: .75;
                }
                > a {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }

    & &__menu {
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: calc(100vh - 200px);

        #{$root}__menu-item {
            padding-top: 20px;
            margin: 0 20px;

            > a {
                font-weight: 700;
                font-size: 16px;
                text-transform: uppercase;
                line-height: 1;
                color: $dulled_black;
            }
        }
    }

    & &__sub-menu {
        padding-top: 15px;

        #{$root}__sub-menu-item {
            padding: 3px 0;
            color: $dulled_black;
            line-height: 16px;
            font-size: 14px;

            :hover {
                color: $dulled_white;
            }
        }
    }

    .js-get-width {
        left: -999999 !important;
        display: block !important;
    }
}