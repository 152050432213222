/*Disabled because of conflict with Bootstrap dropdown*/
/*Do not delete it! Whole directive will be deleted when we would have gotten rid of smart grid*/
.selected {
    /*background-color: #ffcb36 !important;*/
    /*background-color: #1e8ba4 !important;*/
    /*border-color: #4FC1E9 !important;*/
    /*color: #FFF;*/
}

.header-content:after {
    position: relative;
}

.smart-grid .sort-ascent:after {
    content: "\25B4";
    font-size: 16px;
    left: 5px;
}

.smart-grid .sort-descent:after {
    content: "\25BE";
    font-size: 16px;
    left: 5px;
}

.smart-grid .fa-sort-down:before,
.smart-grid .fa-sort-asc:before,
.smart-grid .fa-sort-up:before,
.smart-grid .fa-sort-desc:before {
    content: "";
}

.smart-grid .fa-sort-down:after,
.smart-grid .fa-sort-asc:after {
    content: "\f0dd";
    margin-left: 5px;
}

.smart-grid .fa-sort-up:after,
.smart-grid .fa-sort-desc:after {
    content: "\f0de";
    margin-left: 5px;
}

.smart-grid-header-cell {
    line-height: 20px;
}

.smart-grid-filter-cell {
    border-top: 0px;
    vertical-align: top !important;
}

    .smart-grid-filter-cell button[bs-select] {
        padding-top: 1px !important;
    }

.smart-grid-container .filter input,
.smart-grid-container .filter select {
    padding: 1px 5px 1px 5px;
    margin-bottom: 0px;
    width: 100%;
    height: 23px !important;
    border-radius: 3px;
    border: 1px solid #cccccc;
    color: #777;
}

.smart-grid-input {
    height: 23px !important;
    border-radius: 3px;
    border: 1px solid #cccccc;
}

.smart-grid .input-group span {
    padding-top: 2px;
    padding-bottom: 2px;
}

.smart-grid-select {
    height: 23px;
    width: 100%;
    min-width: 75px;
    border-radius: 3px;
    border: 1px solid #cccccc;
}

.smart-grid-pagination {
    /*height: 25px;*/
}

    .smart-grid-pagination ul {
        display: inline-block;
        padding-left: 0;
        vertical-align: middle;
        margin: 0px;
    }

        .smart-grid-pagination ul li {
            display: inline;
            text-align: center;
            height: 20px;
            line-height: 20px;
        }

            .smart-grid-pagination ul li span {
                height: 25px;
                width: 25px;
                vertical-align: middle;
                text-align: center;
                line-height: 18px;
                position: relative;
                float: left;
                padding: 4px 5px;
                margin: 0px 2px;
                color: #337AB7;
                text-decoration: none;
                background-color: #FFF;
                border: 1px solid #DDD;
                cursor: pointer;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
            }

                .smart-grid-pagination ul li span:hover {
                    background-color: #21BAE6;
                    color: #FFF;
                    border: 1px solid #FFF;
                }

                .smart-grid-pagination ul li span.page {
                    border: none;
                }

.smart-grid-caption {
    padding: .3em .2em .2em .3em;
    color: #444444;
    font-weight: bold;
    font-size: 14px !important;
}

.smart-grid-container .header-content,
.cursor-pointer {
    cursor: pointer;
}

.smart-grid-container .table-bordered {
    border: 0px;
}

.smart-grid-container table th:first-child,
.smart-grid-container table td:first-child {
    border-left: 0px;
}

.smart-grid-container table th:last-child,
.smart-grid-container table td:last-child {
    border-right: 0px;
}

.separator {
    height: 18px;
    border-left: 1px solid #ccc;
    margin-left: 3px;
    margin-right: 5px;
}

.smart-grid .table {
    /*font-size: 11px !important;*/
    /*font-family: Helvetica, sans-serif !important;*/
    margin-bottom: 0px;
    width: 100%;
}

    .smart-grid .table tr {
        background: #FFF;
    }

    .smart-grid .table th {
        color: #1e8ba4;
    }

.smart-grid .table-condensed thead > tr > th,
.smart-grid .table-condensed tbody > tr > th,
.smart-grid .table-condensed thead > tr > td,
.smart-grid .table-condensed tbody > tr > td,
.smart-grid .table-condensed tfoot > tr > td {
    padding: 5px;
}

.smart-grid .table thead > tr > th,
.smart-grid .table tbody > tr > th,
.smart-grid .table thead > tr > td,
.smart-grid .table tbody > tr > td,
.smart-grid .table tfoot > tr > td {
    vertical-align: middle;
    border-top: 1px solid #dddddd !important;
    border-bottom: 1px solid #dddddd;
}

.smart-grid .table-hover tbody tr:hover > td,
.smart-grid .table-hover tbody tr:hover > td i {
    background-color: #1e8ba4;
    border-color: #4FC1E9;
    color: #FFF;
}

.smart-grid .table tbody tr:nth-child(odd) {
    background-color: #FFF;
}

.smart-grid.panel {
    border-left: 1px solid #D1D1D1;
    border-right: 1px solid #D1D1D1;
    border-radius: 5px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

    .smart-grid.panel th {
        min-height: 40px;
        padding: 5px;
        background-color: #F5F5F5;
        border-bottom: 1px solid #DDD;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .smart-grid.panel .smart-grid-data-row {
        padding: 15px;
    }

    .smart-grid.panel .smart-grid-footer-row {
        min-height: 40px;
        padding: 5px 10px;
        background-color: #F5F5F5;
        border-top: 1px solid #DDD;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

.smart-grid .searching {
    opacity: .3;
}

.smart-grid .progress {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    height: 100px;
    width: 100px;
    margin-top: -50px;
    margin-left: -50px;
}

.smart-grid .progress-is-shown .progress-path {
    stroke-width: 6;
}

.smart-grid .progress-svg {
    position: relative;
    height: 100px;
    width: 100px;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.smart-grid .progress-path {
    stroke-width: 0;
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    -webkit-animation: circular 1.5s ease-in-out infinite;
    -moz-animation: circular 1.5s ease-in-out infinite;
    animation: circular 1.5s ease-in-out infinite;
    -webkit-transition-property: stroke-width;
    -moz-transition-property: stroke-width;
    transition-property: stroke-width;
    -webkit-transition-duration: .4s;
    -moz-transition-duration: .4s;
    transition-duration: .4s;
}

@-webkit-keyframes circular {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}

@-moz-keyframes circular {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}

@keyframes circular {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
