//******************* UI Grid header css to flexbox *************
.ui-grid-header-canvas {
    /// Clearfix for floating header cells
    &:before, &:after {
        content: "";
        display: table;
        display: -ms-flexbox;
        display: flex;
        line-height: 0;
    }

    &:after {
        clear:both;
    }
}

.ui-grid-header-cell-wrapper {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.ui-grid-header-cell-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.ui-grid-header-cell > div {
    -ms-flex-basis:100%;
    flex-basis:100%;
}
//******************* End of UI Grid header css to flexbox fix *********

//Fix for Angular UI Bootstrap empty href
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

select {
    background-color: white;
}

.fieldset {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid #c0c0c0;
}

.checkbox__label {
    padding-left: 32px !important;
}

table.borderCorrected {
    border-bottom: 1px solid #e0e0e0;
}
.borderCorrected > tbody > tr > td, .borderCorrected > thead > tr > th {
    border-bottom: 1px solid #e0e0e0;
}

.results_typehead {
    color: $dulled_black;
    margin-top: 0;

    .mdi-close-circle-outline {
        cursor: pointer;
    }
}

.nav.nav-tabs {
        a {
            text-decoration: none;
        }
}

.btn.btn--primary {
    background-color: $button-bgColor-primary;
    color: $button-color-primary;
}

.btn.btn--secondary {
    background-color: $secondary-btn-color;
    color: $primary-color;
}

.btn.btn--m {
    height: $primary-height;
    line-height: $primary-height;
}

.btn--is-disabled.btn--fab,
.btn--is-disabled.btn--raised,
.btn.btn--fab[disabled],
.btn.btn--raised[disabled] {
    color: white !important;
    opacity: .5 !important;
    &.btn--secondary {
        color: $primary-color !important;
    }
}