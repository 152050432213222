.sidebar {
    z-index: 90;
    width: $leftSideBar-width;
    height: 100%;
    position: fixed;
    overflow-y: auto;

    font-family: localSegoeUI, "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: $leftSideBar-bgColor;
    color: $leftSideBar-color;

    .title {
        margin-left: 15px;
        line-height: 37px;
        color: $leftSideBar-title-color;
        font-size: 28px;
        font-weight: bold;
    }
    .table {
        table-layout: fixed;
        td {
            border-bottom: $leftSideBar-border-color solid 1px;
            border-top: $leftSideBar-border-color solid 1px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .infoGroup {
         line-height: 16px;
         min-height: 38px;
         padding-left: 8px;
         p {
             font-size: 15px;
             min-height: 15px;
             color: $leftSideBar-info-color;
         }
     }
    .bottom-border {
        border-bottom: $leftSideBar-border-color solid 1px;
        padding-bottom: 18px;
    }

    .links {
        ul {
            list-style-type: none;
            li {
                height: $leftSideBar-links-height;

                &:hover {
                    cursor: pointer;
                }
                &:hover, &.active {
                    background-color: #EEE;
                    a {
                        color: $leftSideBar-links-color-hovered;
                    }
                }
                i {
                    transform: scale(.5);
                    margin-top: -3px;
                }
                a {
                    text-decoration: none;
                    color: $leftSideBar-info-color;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }
}

@media screen and (max-width: $min-page-width) {
    .sidebar {
        position: absolute;
        overflow: hidden;
        min-height: 777px;
    }
}