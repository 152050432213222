.calendar-tooltip {
    text-align: left;
    max-width: 400px;
    top: -20px;
    left: 50%;
    padding: 10px 20px;
    color: #555555;
    background-color: #ffffff;
    font-weight: normal;
    font-size: 14px;
    border-radius: 8px;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0,0,0,0.5);
    text-align: center;
}

.calendar-tooltip h4 {
    margin-top: 0;
    font-weight: 600;
}