form[name="newreferralForm"] {
    uib-accordion {
        .panel-group {
            max-width: 1266px;
            .panel-default {
                .panel-body {
                    padding-top: 0;
                }
            }
        }
    }

    .newreferral-divider {
        line-height: 64px;
        width: 537px;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            margin-right: 5px;
        }
        &:after {
            background: $newreferral-line-color;
            height: 1px;
            flex: 1;
            content: '';
        }
        &-title {
            color: $newreferral-title-color;
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    .newreferral-bg-wrapper {
        margin-bottom: -90px;
    }

    .newreferral-label {
        color: $select-title-color;
        font-size: 16px;
        margin-bottom: 0;
    }

    .newreferral-error {
        font-size: 10px;
        line-height: 10px;
    }

    .newreferral-input {
        input {
            width: 260px;
            border: none;
            color: $select-dropdown-color;
            padding-left: 8px;
        }
    }

    .newreferral-lineHeight {
        line-height: $newreferral-lineHeight;
    }

    .newreferral-textarea.form-control {
        width: 88%;
    }
}

