html {
    height: 100%;
    background: #FFF;
}

body {
    font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    height: 100%;
    padding-top: $header_height;
    min-width: $min-page-width;
}

.main-content {
    margin-left: $leftSideBar-width;
}

h1.primary {
    font-size: 26px;
    font-weight: bold;
    line-height: 28px;
    color: $primary-color;
}

@media screen and (max-width: $min-page-width) {
    .navbar-carehub {
        position: absolute;
    }

    .footer {
        padding: 2px 10px 2px 10px;
        .totals div {
            padding: 0 2px;
        }
    }
}