$autocomplete-height: 29px;

.newClaimForm {
    .payMember {
        font-size: 14px;
        color: $newClaim-color;
        padding-left: 20px;

        label {
            font-weight: normal;
            text-transform: capitalize;
        }
    }

    .billImage {
        margin-top: 7px;
        line-height: $primary-height;

        div[dropzone] {
            margin-bottom: -20px;
        }
    }

    .searchVendor {
        .form-control {
            height: $autocomplete-height;
        }

        .form-control-feedback {
            width: $autocomplete-height;
            height: $autocomplete-height;
            line-height: $autocomplete-height;
        }
    }

    .dialog__actions {
        padding-right: 40px;
        background-color: unset;
        border-bottom: none;
    }

    .dialog__content {
        max-height: 900px;
    }

    .dialog__scroll__content {
        max-height: 100vh !important;
    }

    .EORImagesDisabled {
        background: #E5E4E2;
    }
}