@function unpack($shorthand) {
    @if length($shorthand) == 1 {
        @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
    } @else if length($shorthand) == 2 {
        @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
    } @else if length($shorthand) == 3 {
        @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
    } @else {
        @return $shorthand;
    }
}

@function is-length($value) {
    @return type-of($value) != "null" and (str-slice($value + "", 1, 4) == "calc"
       or index(auto inherit initial 0, $value)
       or (type-of($value) == "number" and not(unitless($value))));
}

@mixin link-reset() {
    a {
        color: inherit;
        text-decoration: none;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

@mixin menu {
    margin: 0;
    padding: 0;
}

@mixin clear-menu() {
    @include menu;
    list-style: none;
    a {
        text-decoration: none;
    }
}

@mixin showCellContent {
    white-space: normal;
    -ms-text-overflow: clip;
    -o-text-overflow: clip;
    text-overflow: clip;
    overflow: visible;
}

@mixin vertical-align {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin position($position: relative, $coordinates: null null null null) {
    @if type-of($position) == list {
        $coordinates: $position;
        $position: relative;
    }

    $coordinates: unpack($coordinates);

    $offsets: (
            top:    nth($coordinates, 1),
            right:  nth($coordinates, 2),
            bottom: nth($coordinates, 3),
            left:   nth($coordinates, 4)
    );

    position: $position;

    @each $offset, $value in $offsets {
        @if is-length($value) {
            #{$offset}: $value;
        }
    }
}

@mixin arrowBody( $width , $height , $top , $left, $color ){
    border: none;
    background-color: $color;
    height: $height;
    width: $width;
    top: $top;
    left: $left;
}

@mixin arrow( $width , $height , $top , $left, $way, $color){
    left: $left;
    top: $top;
    border-width:$height $width;
    border-#{$way}-color:$color;
}