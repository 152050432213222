.bootstrap-select-wrapper{
    color: $select-dropdown-color;
    font-family: localSegoeUI, "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;

    label {
        color: $select-title-color;
    }

    .bootstrap-select {
        .dropdown-menu > .active > a,
        .dropdown-menu > .active > a:hover,
        .dropdown-menu > .active > a:focus {
            background-color: #F0F0F0;
            color: initial;
        }

        &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
            width: $select-width-default;
        }

        .filter-option-inner-inner {
            padding-left: 8px;
            text-overflow: ellipsis;
            margin-right: 15px;
        }

        .btn {
            text-transform: none;
        }

        .dropdown-menu {
            padding: 0;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 0;
            font-size: 14px;

            & > li > a {
                padding-left: 8px;
                padding-right: 16px;
            }
        }
    }
}
