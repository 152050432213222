.animated {
    -webkit-animation: 1s ease;
    -moz-animation: 1s ease;
    -ms-animation: 1s ease;
    -o-animation: 1s ease;
    animation: 1s ease;
}

.animated-fast {
    -webkit-animation: .2s ease;
    -moz-animation: .2s ease;
    -ms-animation: .2s ease;
    -o-animation: .2s ease;
    animation: .2s ease;
}

.pulse {
    -webkit-animation-name: pulse;
    -moz-animation-name: pulse;
    -ms-animation-name: pulse;
    -o-animation-name: pulse;
    animation-name: pulse;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    100% {
        opacity: 0;
    }
}

@keyframes zoomOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    100% {
        opacity: 0;
    }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutUp {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

/* ng-if animation */
.animate-if-fade.ng-enter {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.animate-if-fade.ng-leave {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

    .animate-if-fade.ng-enter,
    .animate-if-fade.ng-leave.ng-leave-active {
        opacity: 0;
    }

        .animate-if-fade.ng-leave,
        .animate-if-fade.ng-enter.ng-enter-active {
            opacity: 1;
        }

/* router view animation */
.anim-in-out.anim-in-setup.anim-fade,
.anim-in-out.anim-in-setup .anim-fade {
    opacity: 0;
}

.anim-in-out.anim-in.anim-fade,
.anim-in-out.anim-in .anim-fade {
    opacity: 1;
    -webkit-transition: all 200ms;
    transition: all 200ms;
}

.anim-in-out.anim-out-setup.anim-fade,
.anim-in-out.anim-out-setup .anim-fade {
    display: none;
}

.anim-in-out.anim-out.anim-swap,
.anim-in-out.anim-out .anim-swap {
    display: none;
}

.anim-in-out.anim-out.anim-fade,
.anim-in-out.anim-out .anim-fade {
    display: none;
}
