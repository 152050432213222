.custom-slider.rzslider .rz-bar {
    background: #ffe4d1;
    height: 2px;
}
.custom-slider.rzslider .rz-selection {
    background: #00B9E7;
}

.custom-slider.rzslider .rz-pointer {
    width: 8px;
    height: 16px;
    top: auto; /* to remove the default positioning */
    bottom: 0;
    background-color: #00B9E7;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.custom-slider.rzslider {
    .rz-model-value, .rz-model-high {
        font-size: 12px;
    }
}

.custom-slider.rzslider .rz-pointer:after {
    display: none;
}

.custom-slider.rzslider .rz-bubble {
    bottom: 14px;
}

.custom-slider.rzslider .rz-limit {
    font-weight: bold;
}

.custom-slider.rzslider .rz-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: #ffe4d1;
    top: -1px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.custom-slider.rzslider .rz-tick.rz-selected {
    background: #00B9E7;
}