// width of the arrow
$w: 140px;
// height of the arrow
$h: 40px;

.role-admin {
    .active-roles,
    .available-roles {
        border: 2px solid $leftSideBar-border-color;
        overflow-y: scroll;
        height: 50vh;

        & > div {
            line-height: 32px;
        }

        :hover,
        .selected {
            background-color: $selected-item-bgColor;
        }
    }

    .actions-roles {
        height: 50vh;
        display: flex;
        align-items: center;

        .arrow {
            opacity: 0.3;
            cursor: not-allowed;
        }

        .arrow.active {
            opacity: 1;
            cursor: pointer;
        }
    }

    .arrow-right.arrow-red {
        &:before{ @include arrowBody(50%,40%,50% - 40%/2,0,red); }
        &:after { @include arrow($w/2,$h/2,0,50%,left,red); }
    }
    .arrow-left.arrow-green {
        &:before{ @include arrowBody(50%,40%,50% - 40%/2,50%,green); }
        &:after { @include arrow($w/2,$h/2,0,-50%,right,green); }
    }
}

.arrow {
    width: $w;
    height: $h;
    position: relative;
    display: inline-block;
    margin: $h/4 $w/4;

    &:before,
    &:after {
        content: '';
        border-color: transparent;
        border-style: solid;
        position: absolute;
    }
}

