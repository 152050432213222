﻿#homepageWrapper {
    background: #f2f7f9; /* picked from blank area of new-referral-bg.svg */
    height: calc(100vh - 64px); /* adjusted with header height, currently 64px */

    .searchMain {
        position: relative;
        top: calc(45vh - 180px);
        z-index: 100;
        background: white;
        margin: 0 auto;
        padding: 50px;
        border-radius: 20px;
        width: 500px;
        box-shadow: 0px 0px 9px 1px #005f8380;

        .claim-search {
            width: 260px;
            margin-left: 30px;

            input {
                font-style: italic;
            }
        }
    }

    .fractal {
        position: fixed;
        display: inline-block;

        img {
            width: 100%;
        }

        &.fractal-1 {
            top: 35vh;
            left: -470px;
            width: 800px;
        }

        &.fractal-2 {
            top: -20rem;
            right: -17rem;
            width: 900px;
        }
    }
}
