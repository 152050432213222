.clear-focusOutline:focus {
    outline-width: 0;
}

.clear-border {
    border: 0;
}

.clear-borderRadius {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.clear-margin {
    margin: 0 !important;
}

.clear-padding {
    padding: 0 !important;
}

.clear-padding-top {
    padding-top: 0 !important;
}

.clear-padding-bottom {
    padding-bottom: 0 !important;
}

.clear-v-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.clear-padding-left {
    padding-left: 0 !important;
}

.clear-padding-right {
    padding-right: 0 !important;
}

.clear-h-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.margin-left--5 {
    margin-left: -5px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-left-40 {
    margin-left: 40px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-right-40 {
    margin-right: 40px;
}

.margin-right-50 {
    margin-right: 50px;
}

.margin-top--10 {
    margin-top: -10px;
}

.margin-top--5 {
    margin-top: -5px;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-all-10 {
    margin: 10px;
}

.margin-v-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.margin-0 {
    margin: 0;
}

.margin-auto-h {
    margin-left: auto !important;
    margin-right: auto !important;
}

.padding-h-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-15 {
    padding-left: 15px;
}

.padding-left-20 {
    padding-left: 20px;
}

.padding-left-30 {
    padding-left: 30px;
}

.padding-left-40 {
    padding-left: 40px;
}

.padding-left-50 {
    padding-left: 50px;
}

.padding-left-100 {
    padding-left: 100px;
}

.padding-right-0 {
    padding-right: 0 !important;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-right-15 {
    padding-right: 15px;
}

.padding-right-20 {
    padding-right: 20px;
}

.padding-right-30 {
    padding-right: 30px;
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-top-5 {
    padding-top: 5px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-vertical-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-vertical-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-all-50 {
    padding: 50px !important;
}

.padding-all-20 {
    padding: 20px !important;
}

.padding-all-10 {
    padding: 10px;
}

.padding-all-5 {
    padding: 5px !important;
}

primary-height {
    height: $primary-height;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.top-vertical-align {
    vertical-align: top;
}

.vertical-align-sub {
    vertical-align: sub;
}

/* text */
.truncate-text {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.overflow-visible {
    overflow: visible;
}

.clear-text-decoration {
    text-decoration: none !important;
}

.text-stand-out {
    text-shadow: 5px 5px 10px rgba(150, 150, 150, 1);
    cursor: pointer;
}

.text-white {
    color: #FFF;
}

.text-primary {
    color: $primary-color;
}

.text-primary-medium {
    color: #1e8aa3;
    font-size: 16px;
}

.text-primary-medium:hover {
    color: #00a6cf;
}

.text-grey {
    color: rgba(0, 0, 0, 0.75);
}

.text-lightgrey {
    color: rgba(0, 0, 0, 0.50);
}

.text-greyed {
    color: #789;
}

.text-success {
    color: #39BB9D;
}

.text-success:hover {
    color: #33a88d;
}

.text-danger {
    color: #ED5565;
}

.text-danger:hover {
    color: #DA4453;
}

.icon-red {
    color: $claimDetail-icon-redColor;
}

.icon-blue {
    color: $claimDetail-icon-blueColor;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: bold;
}

.text-small {
    font-size: small;
}

.text-medium {
    font-size: medium;
}

.text-large {
    font-size: large;
}

.font-12 {
    font-size: 12px;
}

.font-20 {
    font-size: 20px;
}

.text-underline {
    text-decoration: underline;
}

.text-italic {
    font-style: italic;
}

.greyed {
    opacity: 0.5;
}

.link {
    outline: 0;
    text-decoration: underline;
    cursor: pointer;
}

.link-nounderline {
    outline: 0;
    cursor: pointer;
    a {
        color: $primary-color !important;
    }
}

.disabled {
    cursor: not-allowed !important;
}

.caret {
    margin-left: 0;
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

/* helpers */
.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.inline {
    display: inline;
}

.list-inline {
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
}

.list-inline > li {
    display: block;
    padding-left: 5px;
    padding-right: 5px;
}

.rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%
}

.h-100 {
    height: 100%;
}

.circle-indicator-15px {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 8px;
}

.position-top-0 {
    top: 0;
}

