.save-button-wrapper {
    position: fixed;
    z-index: 999;
    right: 16px;
    bottom: 16px;

    background-color: $save-button-bgColor;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: $save-button-width;
    min-height: $save-button-height;
    line-height: $save-button-height - 2px;
    font-weight: bold;
    color: $leftSideBar-title-color;
    i {
        transform: scale(.5);
        vertical-align: middle;
    }
    button {
        vertical-align: middle;
        min-width: 100px;
    }
}

@media only screen and (max-width: $min-page-width) {
    .save-button-wrapper {
        position: initial;
        margin-left: auto;
        margin-right: 15px;
        margin-bottom: 10px;
    }
}