
.payBills {
    font-family: localSegoeUI, "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 16px;
    border: none;
    color: $claims-color;

    .min-width-80 {
        min-width: 80px;
    }

    .payBills-grid {
        .ui-grid-viewport {
            overflow-anchor: none;
            height: 55vh !important;

            .ui-grid-row {
                .ui-grid-cell {
                    &.pink {
                        background-color: pink;
                    }
                    &.yellow {
                        background-color: yellow;
                    }
                    &.orange {
                        background-color: orange;
                    }
                    &.purple {
                        background-color: mediumpurple;
                    }
                }
            }
        }
        .ui-grid-header {
            border: none;
            color: $claims-color;
            &-cell {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                border-right: none;
                border-left: none;
                border-top: 2px solid $primary-color;
                border-bottom: 1px solid;
                border-bottom-color: $leftSideBar-border-color;
                background-color: $claims-header-bgColor;
                &.text-capitalize {
                    text-transform: capitalize !important;
                }
                div[role="columnheader"] {
                    height: inherit;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .ui-grid-row:nth-child(even) .ui-grid-cell {
            background-color: $claims-grid-bgColor2;
        }

        .ui-grid-contents-wrapper {
            .ui-grid-row {
                .ui-grid-cell {
                    border-left: none;
                    border-right: none;

                    & .ui-grid-cell-contents {
                        input[type="checkbox"] {
                            margin: 0;
                        }
                        a {
                            color: initial;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .uib-pagination {
            display: flex;
            align-items: center;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
            min-height: 44px;

            .pagination-prev:not(.disabled) > a,
            .pagination-next:not(.disabled) > a {
                color: $primary-color !important;
            }

            .color-legend {
                margin-top: 9px;
                ul {
                    list-style: none;
                    li {
                        float: left;
                        margin-right: 10px;
                    }
                    span {
                        border: 1px solid #ccc;
                        float: left;
                        width: 12px;
                        height: 12px;
                        margin: 2px 2px 2px 2px;
                    }
                }
            }
            .pager-row-count-picker {
                border: 1px solid $payBills-border-color;
                box-sizing: border-box;
                border-radius: 2px;
            }
            .payBills-export {
                button {
                    min-width: 72px;
                }
            }
            .pagination {
                > .active > a,
                > .active > span {
                    &,
                    &:hover,
                    &:focus {
                        color: $claims-color !important;
                        background-color: white;
                        border-color: white;
                        cursor: default;
                        text-decoration: underline;
                    }
                }
            }
            .pagination > li > a {
                width: auto;
                height: auto;
            }
        }

        .ui-grid-header-cell .ui-grid-cell-contents {
            height: 48px;
            @include showCellContent();
            display: flex;
            align-items: center;
        }

        .alignedCheckbox {
            text-align: center;
            justify-content: center;

            input[type="checkbox"] {
                margin-left: 0;
            }
        }

        .noDataForGrid {
            @include vertical-align();
            opacity: 0.5;
            font-size: 40px;
            width: 100%;
            text-align: center;
        }
    }
}

.payBills-grid {
    @media only screen and (min-width: 1920px) {
        min-height: 70vh;
    }
    @media only screen and (min-width: 1441px) and (max-width: 1919px){
        min-height: 55vh;
    }
    @media only screen and (max-width: 1440px){
        min-height: 40vh;
    }
}

