.odometer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

    .odometer .odometer-digit {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        position: relative;
    }

        .odometer .odometer-digit .odometer-digit-spacer {
            display: inline-block;
            vertical-align: middle;
            *vertical-align: auto;
            *zoom: 1;
            *display: inline;
            visibility: hidden;
        }

        .odometer .odometer-digit .odometer-digit-inner {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }

        .odometer .odometer-digit .odometer-ribbon {
            display: block;
        }

        .odometer .odometer-digit .odometer-ribbon-inner {
            display: block;
            -webkit-backface-visibility: hidden;
        }

        .odometer .odometer-digit .odometer-value {
            display: block;
            -webkit-transform: translateZ(0);
        }

            .odometer .odometer-digit .odometer-value.odometer-last-value {
                position: absolute;
            }

    .odometer.odometer-animating-up .odometer-ribbon-inner {
        -webkit-transition: -webkit-transform 2s;
        -moz-transition: -moz-transform 2s;
        -ms-transition: -ms-transform 2s;
        -o-transition: -o-transform 2s;
        transition: transform 2s;
    }

    .odometer.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    .odometer.odometer-animating-down .odometer-ribbon-inner {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    .odometer.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
        -webkit-transition: -webkit-transform 2s;
        -moz-transition: -moz-transform 2s;
        -ms-transition: -ms-transform 2s;
        -o-transition: -o-transform 2s;
        transition: transform 2s;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    .odometer.theme-large-blue .odometer-digit {
        font-size: 50px;
        color: #FFF;
    }

    .odometer.theme-large-white .odometer-digit {
        font-size: 50px;
        color: #00B9E7;
    }

        .odometer.theme-large-white .odometer-digit .odometer-digit-inner {
            background-color: #FFF;
            padding: 0 2px;
        }

    .odometer.theme-large-white .odometer-digit,
    .odometer.theme-large-blue .odometer-digit,
    .odometer.theme-small-blue .odometer-digit {
        border: 1px solid silver;
        padding: 0 2px;
    }

        .odometer.theme-large-white .odometer-digit + .odometer-digit,
        .odometer.theme-large-blue .odometer-digit + .odometer-digit,
        .odometer.theme-small-blue .odometer-digit + .odometer-digit {
            margin-left: 0.05em;
        }

    .odometer.theme-small-blue {
        color: #FFF;
    }

        .odometer.theme-small-blue .odometer-digit {
            font-size: 25px;
            color: #FFF;
        }

        .odometer.theme-small-blue .odometer-digit-inner,
        .odometer.theme-large-blue .odometer-digit-inner {
            background-color: #00B9E7;
        }

        .odometer.theme-small-blue .odometer-digit .odometer-digit-inner,
        .odometer.theme-large-blue .odometer-digit .odometer-digit-inner {
            padding: 0 2px;
        }


.odometer-text {
    border: 1px solid #C0C0C0;
    padding: 0px 2px;
    margin: 0px 3px;
    font-size: 50px;
    line-height: 1.6;
    color: #FFF;
    background-color: #00B9E7;
}
