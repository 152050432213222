.grid-component {
    font-size: 14px;
    border: none;
    color: rgba(0, 0, 0, 0.7);

    .ui-grid-header-cell-wrapper{
        .ui-grid-header-cell {
            &.grid-component_header_actions{
                background-color: white;
            }
        }
    }

    &_header {
        &:not(:last-child) {
            font-weight: bold;
            border-right: none;
            border-left: none;
            border-bottom: 1px solid;
            border-bottom-color: $leftSideBar-border-color;
            background-color: white;
            text-align: left;
        }

        &_actions {
            background-color: white;
        }
    }

    &_actions {
        text-align: center;
        i {
            opacity: 0.5;
            padding-top: 5px;
            padding-bottom: 5px;
            &:hover {
                opacity: 1;
                transform: scale(1.5);
                cursor: pointer;
            }
        }
    }

    &_select {
        color: $select-dropdown-color;
        width: 100%;
        border: none;
        background-color: inherit;
    }

    &_select option {
        font-size: 14px;
        background-color: white;
        &:checked {
            background-color: #F0F0F0;
        }
        &:hover {
            box-shadow: 0 0 10px 100px #000 inset;
        }
    }

    &_validation {
        white-space: normal;
        text-overflow: clip;
    }

    &_addRow {
        color: $primary-color;
        span:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &_footer {
        width: 98%;
    }

    .ui-grid-contents-wrapper {
        .ui-grid-header{
            border: none;
        }
        .ui-grid-header-cell .ui-grid-cell-contents {
            height: 48px;
            @include showCellContent();
        }
        .ui-grid-header-cell {
            div[role="columnheader"] {
                div[role="button"] {
                    span {
                        vertical-align: sub;
                    }
                }
            }
        }

        .showExtendedContent .ui-grid-cell-contents{
            @include showCellContent();
        }

        .ui-grid-row .ui-grid-cell {
            background-color: transparent;
            border-bottom: 1px solid grey;
        }

        .grid-component_header,
        .grid-component_header__visible_actions{
            display:flex;
            align-items: center;
            border: none;
            background-color: $claims-grid-bgColor2;
            .ui-grid-cell-contents{
                display:flex;
                align-items: center;
                outline: none;
            }
        }

        .grid-component_header__visible_actions{
            text-align: center;
        }

        .ui-grid-row {
            text-align: left;
            line-height: 46px;

            .ui-grid-cell {
                input[type=checkbox] {
                    transform: scale(1.5);
                }
                input[disabled] {
                    cursor: not-allowed;
                    background-color: transparent;
                    border: none;
                }
                input[readonly] {
                    cursor: not-allowed;
                }

                &.autocomplete-w-90 > .ui-grid-cell-contents {
                    width: 90%;
                }

                &:not(:last-child),
                &.visible_last_row{
                    border-left: none;
                    border-right: none;
                    border-bottom-width: 1px;
                    border-color: $leftSideBar-border-color;
                }

                &.grid-component_actions {
                    border: none;
                    background-color: white;
                }

                & .ui-grid-cell-contents {
                    line-height: 36px;
                    &.color-legend {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        div {
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            border-radius: 4px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                &.overflow-visible {
                    .ui-grid-cell-contents {
                        overflow: visible;
                    }
                }

                &-focus {
                    background-color: transparent;
                }
            }
        }
    }

}