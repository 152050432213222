//******************************* Global Bootstrap *********************************
$zindex-navbar-fixed: 1001 !default;
$grid-float-breakpoint: 400px;

//******************************* Custom Bootstrap Select **************************
$select-width-default: 266px;
$select-title-color: #404040;
$select-dropdown-color: rgba(0, 0, 0, 0.7);

//******************************* CH Input  ****************************************
$error-color: #EB5757;
$ch-input-border-color: #C4C4C4;
$ch-input-color: #474A56;
$ch-input-bg-color: #F0F0F0;

//******************************* Buttons  *****************************************
$secondary-btn-color: #3BABB8;

//******************************* Layout *******************************************
$min-page-width: 1210px;
$primary-color: #005F83;
$primary-height: 32px;
$invalid-color: #fc8f8f;
$selected-item-bgColor: rgb(238, 238, 238);


//******************************* Header *******************************************
$navbar-default-brand-color: white;
$navbar-default-link-color: white;

$header_height: 64px;
$search_form_height: 38px;
$search_form_width: 252px;

$dulled_white: rgba(255, 255, 255, .75);
$dulled_black: rgba(0, 0, 0, 0.7);

//******************************* Invalid Page ************************************
$error_gray: #404040;

//******************************* Left Side Bar ***********************************
$leftSideBar-width: 285px;
$leftSideBar-links-height: 34px;
$leftSideBar-bgColor: #f5f5f5;
$leftSideBar-color: rgba(0, 0, 0, 0.5);
$leftSideBar-title-color: #4D4D4D;
$leftSideBar-border-color: rgba(0, 0, 0, 0.2);
$leftSideBar-info-color: $dulled_black;
$leftSideBar-links-color-hovered: $primary-color;

//******************************* Flag *******************************************
$flag-color: #EB5757;
$flag-bgColor: #FFD2D2;
$flag-borderColor: #fff;

//******************************* Save Button ************************************
$save-button-bgColor: #EDEDED;
$save-button-width: 400px;
$save-button-height: 65px;


//******************************* New Referral ***********************************
$newreferral-lineHeight: 27px;
$newreferral-title-color: #999999;
$newreferral-line-color: rgba(0, 0, 0, 0.2);

//*******************************  New Claim  ***********************************
$newClaim-color: #585858;

//******************************* CareGuard Claims (Claim History) ***************
$claims-color: #333333;
$claims-bgColor: #ffffff;
$claims-header-bgColor: #b7dce9;
$claims-grid-bgColor: #E5E5E5;
$claims-grid-bgColor2: #DEEFF5;
$button-bgColor-primary: $primary-color;
$button-color-primary: #ffffff;
$claims-color-muted: rgba(68, 68, 68, 0.5);

//******************************* Claim Details **********************************
$claimDetails-border-color: $newreferral-title-color;
$claimDetails-border-color: #ccc;
$claimDetail-icon-redColor: #C30102;
$claimDetail-icon-blueColor: #1E8AA3;
$claimDetails-hierarchy-background-color: #F8FAFF;
$claimDetails-hierarchy-border-color: #F1F4F5;
$claimDetails-hierarchy-link: #00B9E7;
$claimDetails-hierarchy-text-color: #999;
$claimDetails-flags-color: #ED5565;
$claimDetails-icons-color: #9C9C9C;

//******************************* Pay Bills *************************************
$payBills-border-color: #D9D9D9;