[flex-item="12"] {
    width: 100%; /*CHANGE THIS TO 70 TO SHRINK*/
}

[flex-gutter="24"] {
    margin: 0px;
}


.text-field[disabled]::before {
    border-bottom-style: dotted;
}

.lx-select__floating-label,
.text-field--is-active .text-field__label {
    transform: scale(0.95);
    color: #1e8aa3;

}

.lx-select__selected:after {
    font-weight: 900;
    color: black;
    font-size: 35px;
    right: 4px;
    bottom: 12px;
}

.lx-select__choices {
    width: auto !important;
}

.text-field__input,
.lx-select__selected span[ng-transclude],
.radio-button__label:after
 {
    color: black;
    box-shadow: none;
}

.checkbox__label:after {
    color: #1e8aa3;
    box-shadow: none;
}
.checkbox__label.text-success:after {
    color: #39BB9D;
}

/*.lx-select.ng-invalid .lx-select__selected span*/
.text-field.text-field--has-error .text-field__input {
    color: #ED5565;
}


.red { 
  background-color: red;
}
.black {
   background-color : black;
}
.yellow {
    background-color: yellow;
}
.green {
   background-color: green;
}
.lightgreen {
   background-color: lightgreen;
}
.orange {
    background-color: orange;
}
.purple {
  background-color: mediumpurple;
}


.text-field__label,
.lx-select__floating-label,
.lx-select__selected--placeholder {
    /*color: rgba(0, 0, 0, 0.75);*/
    color: #1e8aa3;
    font-weight: bolder;
}

.text-field[disabled] .text-field__label {
    color: rgba(0, 0, 0, 0.40);
}

.checkbox__label {
    display: inline;
}

.radio-group.radio-inline {
    padding-top: 10px;
}

.radio-button__label,
.checkbox__label {
    opacity: 0.75;
}

.radio-group.radio-inline .radio__label,
.switch .floating-label {
    display: block;
    /*position: absolute;*/
    top: 8px;
    left: 0px;
    line-height: 32px;
    pointer-events: none;
    color: #999999;
    transform: translateY(24px);
    transform-origin: left bottom 0px;
    transition-property: transform, color;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    transform: scale(0.95);
}

.radio-group.radio-inline .radio-button {
    display: inline;
}

    .radio-group.radio-inline .radio-button label {
        display: inline;
        padding-top: 3px;
        padding-right: 5px;
    }

.switch {
    padding-bottom: 15px;
}

.switch__input:checked + .switch__label:after {
    background-color: #39BB9D;
}

.switch .floating-label {
    position: relative;
    top: 2px;
}

.btn--is-disabled.btn--fab,
.btn--is-disabled.btn--raised,
.btn.btn--fab[disabled],
.btn.btn--raised[disabled] {
    opacity: 0.8;
}

.btn--fab.btn--orange,
.btn--raised.btn--orange {
    background-color: #F37021;
    border-color: #F37021;
}

.btn--orange:hover,
.btn--orange:focus,
.btn--orange:active,
.btn--orange.active {
    background-color: #DA641D;
    border-color: #DA641D;
    cursor: pointer;
}

.btn--fab.btn--green,
.btn--raised.btn--green {
    background-color: #39BB9D;
    border-color: #33a88d;
}

.btn--green:hover,
.btn--green:focus,
.btn--green:active,
.btn--green.active {
    background-color: #33a88d;
    border-color: #33a88d;
    cursor: pointer;
}

.btn--fab.btn--blue,
.btn--raised.btn--blue {
    background-color: #00B9E7;
    border-color: #00a6cf;
}

.btn--blue.btn--raised:hover,
.btn--blue.btn--raised:focus,
.btn--blue.btn--raised:active,
.btn--blue.btn--raised.active {
    background-color: #00a6cf !important;
    border-color: #00a6cf !important;
}

.btn--fab.btn--red,
.btn--raised.btn--red {
    background-color: #ED5565;
    border-color: #DA4453;
}

.btn--red:hover,
.btn--red:focus,
.btn--red:active,
.btn--red.active {
    background-color: #DA4453;
    border-color: #DA4453;
}

.data-table--has-primary > tbody > tr > td:first-child,
.data-table--has-primary > thead > tr > th:first-child {
    padding-left: 0px;
    padding-right: 0px;
}

.data-table > tbody > tr > td,
.data-table > thead > tr > th {
    height: 32px;
    padding: 10px;
}

.no-filter .lx-search-filter__container {
    display: none !important;
}

.no-filter .dropdown-filter {
    display: none !important;
}

.lx-select__choices ul {
    max-height: 400px;
    overflow: auto;
}

.dropdown-menu ul {
    min-width: 125px;
}

.mdlink {
    outline: 0;
    cursor: pointer;
}

.mdlink-underline {
    outline: 0;
    cursor: pointer;
    text-decoration: underline;
}

.mdi-1x {
    font-size: 16px;
}

.mdi-2x {
    font-size: 18px;
}

.mdi-3x {
    font-size: 20px;
}

.mdi-4x {
    font-size: 22px;
}

.mdi-10x {
    font-size: 30px;
}

table .text-field {
    padding-top: 0px;
}

table .lx-select {
    margin-top: 0px;
}

.dialog {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.dialog__header .toolbar {
    /*-webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;*/
}

.fs-display-1 {
    font-weight: bold;
    
    /*Add this to shrink down the header*/
    font-size: 24px;
    line-height:normal;
}

.toolbar {
    height: 32px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: inherit;
}

.toolbar__label {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 32px;
    color:lightslategray;
}

.toolbar__right {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -o-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: end;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    position: relative;
}
.dialog__actions {
    min-height: 40px;
    padding: 5px 10px;
    margin-top: 15px;
    background-color: #F5F5F5;
    border-bottom: 1px solid #DDD;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* scale in */
/*.dialog {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.dialog--is-shown {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}*/

/* scale out */
/*.dialog {
    -webkit-transform: scale(2);
	-moz-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.dialog--is-shown {
   -webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}*/
/* slide */
.dialog {
    top: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transform: translateY(-200%);
    -moz-transform: translateY(-200%);
    -ms-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s;
    opacity: 0;
}

.dialog--is-shown {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    border-radius: 0 0 3px 3px;
    opacity: 1;
}

.tc-blue {
    color: #00B9E7 !important;
}

.bgc-blue {
    background-color: #00B9E7 !important;
}

.text-field {
	position: relative;
	padding-top: 0px;
	padding-bottom: 8px;
	font-weight: bolder;
}

.text-field__label {
	display: block;
	position: inherit;
	top: 0px;
	font-size: small;
}

.styled-select-div {
	font-size: small;
}