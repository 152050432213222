form[name="createUserForm"] {
    uib-accordion {
        .panel-group {
            max-width: 1266px;

            .panel-default {
                .panel-body {
                    padding-top: 0;
                }
            }
        }
    }

    .useradmin-bg-wrapper {
        margin-bottom: -90px;
    }

    .useradmin-label {
        color: $select-title-color;
        font-size: 16px;
        margin-bottom: 0;
    }

    .useradmin-error {
        font-size: 10px;
        line-height: 10px;
    }

    .useradmin-input {
        input {
            width: 100%;
            color: $select-dropdown-color;
            padding-left: 8px;
        }
    }
}
