.pagination {
	margin: 0;
}

ul.pagination {
	list-style: outside none none;
	float: left;
}

.pagination li {
	display: inline;
	vertical-align: middle;
}

.pagination li.disabled i {
	opacity: .25;
}

.pagination a,
.pagination span {
	text-decoration: none;
	vertical-align: middle;
	color: #777 !important;
	cursor: pointer;
	position: relative;
    height: 25px;
    width: 25px;
	margin: 0 !important;
	float: none !important;
	display: inline-block;
	padding: 3px 8px !important;
	border: 0 none !important;
	outline: none !important;
	background-color: transparent !important;
	border-radius: 50% !important;
	-webkit-border-radius: 50% !important;
	-moz-border-radius: 50% !important;
}

dir-pagination-controls {
    .pagination > li > a:hover,
    .pagination > li > a:focus,
    .pagination > li.active > a {
        color: #FFF !important;
        background-color: #1e8aa3 !important;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}

.pagination i {
	vertical-align: middle;
	color: #444 !important;
	background-color: transparent !important;
	opacity: .45;
}

.pagination i:hover {
	opacity: 1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.pager-controls .controls {
	padding-top: 4px;
}

.pager-controls .control-text {
	color: #444;
	opacity: .45;
}

.pager-controls .controls i {
	color: #444;
	opacity: .45;
	cursor: pointer;
	margin-left: 5px;
	margin-right: 5px;
}

.pager-controls .controls i:hover {
	opacity: 1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}