#login ::-webkit-input-placeholder { /* WebKit browsers */
    color: #1e8aa3;
}

#login :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #1e8aa3;
    opacity: 1;
}

#login ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #1e8aa3;
    opacity: 1;
}

#login :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #1e8aa3;
}

#diarySearch ::-webkit-input-placeholder { /* WebKit browsers */
    color: #1e8aa3;
}

#diarySearch :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #1e8aa3;
    opacity: 1;
}

#diarySearch ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #1e8aa3;
    opacity: 1;
}

#diarySearch :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #1e8aa3;
}

hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

small {
    font-weight: normal;
    font-size: 12px;
}

a,
a:active,
a:hover,
a:focus {
    outline: none;
    color: #005d83;
}

/* backgrounds */
.bg-blue {
    background-color: #5fa8db;
}

.bg-orange {
    background-color: #F37021;
}

.bg-green {
    background-color: #39BB9D;
}

.bg-lightgrey {
    background-color: #F6F6F6;
}

.bg-darkgrey {
    background-color: #808285;
}

.bg-lightblue {
    background-color: #e7f6fd;
}

.bg-mediumblue {
    background-color: #68c8e5;
}

.bg-clear {
    background-color: transparent;
}

.submenu {
    background:#5fa8db;
    background-size: contain;
    border: 1px solid #D1D1D1;
    padding: 5px 50px 5px 5px;
    height: 65px;
    font-size: 14px;
}

.logo {
    width: 100%;
    max-width: 125px;
    display: block;
}

.caption {
    border-bottom: 1px solid #1e8aa3;
    color: #1e8aa3;
    display: block;
    width: 100%;
    padding: 10px 0 0 0;
    margin-bottom: 0;
    font-size: 21px;
    line-height: inherit;
}

.stand-out {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 185, 231, 0.26);
    padding: 15px;
    margin-bottom: 5px;
    background: #FFF;
}

.border-blue {
    border-color: #1e8aa3 !important;
}

.border-white {
    border-color: #FFF;
}

.border-dark {
    border-color: #CCC !important;
}

.border-all {
    border: 1px solid #EEE;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.border-right {
    border-right: 1px solid #EEE;
}

.border-left {
    border-left: 1px solid #EEE;
}

.border-top {
    border-top: 1px solid #EEE;
}

.border-bottom {
    border-bottom: 1px solid #EEE;
}

.border-round-right {
    -moz-border-bottom-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.border-round-bottom {
    -moz-border-bottom-right-radius: 8px;
    -moz-border-bottom-left-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.fa-1x {
    font-size: 1.5em;
}

/* text */
.input-material {
    border-width: 0 0 1px;
    border-style: none none solid;
    border-bottom: 1px solid #CCC;
    box-shadow: none;
}

    .input-material[disabled] {
        border-bottom-style: dotted;
    }

span.input-material {
    border-bottom-style: dotted;
}

.input-group input {
    font: 400 14px Roboto;
    line-height: 1.5;
    border-radius: 3px;
    color: #1e8aa3;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    background-color: #FFF;
    background-image: none;
    border: 1px solid #CCC;
    box-shadow: 0 1px 1px rgba(0,0,0,0.075) inset;
    transition: border-color .15s ease-in-out 0 box-shadow .15s ease-in-out 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 5px 10px;
}

.input-group-btn {
    margin-left: -4px;
    position: relative;
    font-size: 0;
    white-space: nowrap;
    width: auto;
    vertical-align: middle;
}

    .input-group-btn button {
        border-radius: 0 3px 3px 0;
        font-size: 14px;
        line-height: 1.5;
        position: relative;
        z-index: 2;
        display: inline-block;
        vertical-align: top;
        border: medium none;
        text-align: center;
        cursor: pointer;
        background-color: #86c2c0;
        white-space: nowrap;
        color: #FFF;
        margin: 0;
        padding: 6px 10px;
    }

input.input-focus:focus {
    border-color: #66AFE9;
    outline: 0 none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

.text-transform-none {
    text-transform: none !important;
}

/* grid */
.card-list-group-item {
    position: relative;
    display: block !important;
    padding: 5px;
    background-color: #fff;
    color: #333;
    border-bottom: 1px solid #ddd;
}

    .card-list-group-item.active {
        background: #1e8aa3;
        color: #FFF;
    }

    .card-list-group-item:hover {
        background: #F6F6F6;
        color: #333;
    }

    .card-list-group-item.active:hover {
        background: #00a6cf;
        color: #FFF;
    }

    .card-list-group-item.active:after {
        font-family: MaterialDesignIcons;
        content: "";
        position: absolute;
        right: 5px;
        top: 50%;
        font-size: 2em;
        line-height: 14px;
        opacity: .3;
        margin-top: -7px;
        text-decoration: inherit;
        text-transform: none;
    }

    .card-list-group-item i {
        opacity: .3;
        width: 40px;
        text-align: center;
    }

    .card-list-group-item .header-item {
        display: inline-block;
        font-size: 14px;
    }

/* note */
.post {
    position: relative;
    border-width: 1px 0 0 0;
    border-color: #DCDCDC;
    border-style: solid;
    padding: 5px 10px;
    font-size: 14px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    min-height: 40px;
}

    .post:last-child {
        border-width: 1px 0 1px 0;
        border-color: #DCDCDC;
    }

    .post .author {
        display: block;
        font-size: 11px;
        line-height: 20px;
        color: #1e8aa3;
    }

    .post .item-label {
        color: #fff;
        font-size: 12px;
        height: 20px;
        width: 150px;
        position: absolute;
        font-weight: bold;
        padding-left: 8px;
        top: 10px;
        right: -5px;
        margin-right: 0;
        border-radius: 3px 3px 0 3px;
        box-shadow: 0 3px 3px -2px #ccc;
        text-transform: capitalize;
        background-color: #3BAFDA;
    }

        .post .item-label:after {
            top: 20px;
            right: 0;
            border-top: 4px solid #585858;
            border-right: 4px solid rgba(0,0,0,0);
            content: "";
            position: absolute;
        }



/* panel */
.panel {
    padding: 0 !important;
}

.panel-heading-default {
    font: 200 18px Roboto;
    min-height: 40px;
    padding: 5px 10px;
    background-color: #F5F5F5;
    border-bottom: 1px solid #DDD;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-heading {
    /************************commented till the end of claim details redesign part2, then will be deleted*/
    /*font: 200 18px Roboto;*/
    /*color: #FFF !important;*/
    /*border: 1px solid #1e8aa3;*/
    background-size: contain;
    padding: 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-body {
    padding: 15px;
}

.panel-footer {
    min-height: 40px;
    padding: 5px 10px;
    background-color: #F5F5F5;
    border-top: 1px solid #DDD;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
/****************************commented till the end of claim details redesign part2, then will be deleted*/
/* tabs */
/*.nav-tabs {*/
    /*border-bottom: medium none;*/
    /*padding: 0;*/
    /*margin: 0;*/
    /*list-style: outside none none;*/
/*}*/

    /*.nav-tabs li {*/
        /*margin: 0 5px;*/
        /*padding-bottom: 4px;*/
        /*float: left;*/
        /*position: relative;*/
        /*display: block;*/
    /*}*/

        /*.nav-tabs li a {*/
            /*line-height: 1px;*/
            /*border: 0 none !important;*/
            /*padding: 10px 3px;*/
            /*display: block;*/
            /*background-color: inherit !important;*/
            /*text-decoration: none;*/
        /*}*/

            /*.nav-tabs li a,*/
            /*.nav-tabs li a:visited,*/
            /*.nav-tabs li a:link,*/
            /*.nav-tabs li a:focus,*/
            /*.nav-tabs li a:hover {*/
                /*color: #FFF;*/
            /*}*/

    /*.nav-tabs.primary li a,*/
    /*.nav-tabs.primary li a:visited,*/
    /*.nav-tabs.primary li a:link,*/
    /*.nav-tabs.primary li a:focus,*/
    /*.nav-tabs.primary li a:hover {*/
        /*color: #1e8aa3;*/
    /*}*/

    /*.nav-tabs li.active,*/
    /*.nav-tabs li:hover {*/
        /*border-bottom: 2px solid #FFF;*/
    /*}*/

    /*.nav-tabs.primary li.active,*/
    /*.nav-tabs.primary li:hover {*/
        /*border-bottom: 2px solid #1e8aa3;*/
    /*}*/



/* error */
.error {
    color: #ED5565;
    display: inline-block;
    cursor: pointer;
}

.error-message {
    color: #ED5565;
}

.error-box {
    background-color: #F5CCD3;
    color: #333;
    padding: 10px;
    box-shadow: 0 1px 0 rgba(255,255,255,0.5) inset, 0 1px 0 #fff;
    border: 1px solid #999;
    text-shadow: 0 1px 0 rgba(255,255,255,0.5);
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.error-box-no-border {
    background-color: #F5CCD3;
    border-color: #E498A3;
    color: #333;
    padding: 10px;
    box-shadow: 0 1px 0 rgba(255,255,255,0.5) inset, 0 1px 0 #fff;
    text-shadow: 0 1px 0 rgba(255,255,255,0.5);
}

.loader {
    margin: 6em auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0, 255, 255, 0.2);
    border-right: 1.1em solid rgba(0, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(0, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }

.btn-loading {
    left: 50%;
    margin-left: -16px;
    -webkit-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.font-large {
    font-size: 25px;
}

.dahboard-box-large {
    min-height: 175px;
    border-left: 1px solid silver;
}

.dahboard-triangle-expand {
    position: absolute;
    color: #FFF;
    height: 27px;
    width: 18px;
    font-size: 24px;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.dahboard-triangle-blue {
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    border-bottom: 35px solid #1e8aa3;
    border-left: 35px solid transparent;
    position: absolute;
}

.dahboard-triangle-lightblue {
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    border-bottom: 35px solid #e7f6fd;
    border-left: 35px solid transparent;
    position: absolute;
}

.dashboard-box-header {
    color: #1e8aa3;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
}

.dashboard-box-light {
    color: #E7F6FD;
}

/* responsive utilites */
@media screen and (max-width:1023px) {
    .hide-small {
        display: none;
    }
}

@media screen and (min-width:1024px) {
    .hide-large {
        display: none;
    }
}

.file-input-wrapper {
    width: 120px;
    height: 40px;
    overflow: hidden;
    position: relative;
}

    .file-input-wrapper > input[type="file"] {
        font-size: 200px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
    }

    .file-input-wrapper > .btn-file-input {
        z-index: 2;
        display: inline-block;
        vertical-align: top;
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 2px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        width: 120px;
        height: 40px;
    }


/* busy directive */
[chronovo-busy].is-busy {
    cursor: wait;
}

    [chronovo-busy].is-busy::before {
        content: '';
        width: 1em;
        height: 1em;
        display: inline-block;
        border: solid 2px transparent;
        border-top-color: white;
        border-left-color: white;
        border-radius: 50%;
        margin-right: 0.5em;
        -webkit-animation: v-busy-spin 0.75s linear infinite;
        animation: v-busy-spin 0.75s linear infinite;
    }

@-webkit-keyframes v-busy-spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes v-busy-spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.lx-date-icon{
    margin-top: 24px;
}

#navigation-toggle {
        position : absolute;
        left : 0;
        background : rgba(3,159,244,.95);
        color : white;
        text-decoration : none;
        padding : 20px;
}

.show {
    left : 0;
}

.navigation {
    list-style : none;
    padding : 0;
    margin : 0;
}

.navigation-items a {
    display : block;
    background-color : #1e8aa3;
    color : white;
    line-height : 2em;
    text-decoration : none;
    padding : 10px 30px;
    width : 150px;
}

.navigation-items a:hover {
    background-color :#1e8aa3;
}

.menu {
    background: #eaf0f9;
    background-size: contain;
    border: 1px solid #D1D1D1;
    padding: 5px 50px 5px 5px;
    font-size: 14px;
}

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu {
  font-family: 'Aller Light', sans-serif;
}

#cssmenu > ul {
  background: #ffffff;
}

#cssmenu > ul > li > a {
  padding: 12px 15px;
  font-size: 17px;
  color: rgb(38, 179, 211);
  font-weight: 700;
  text-decoration: none;
  -webkit-transition: color .2s ease;
  -o-transition: color .2s ease;
  -ms-transition: color .2s ease;
  transition: color .2s ease;
  background: rgb(220, 233, 235);
}

#cssmenu > ul > li:hover > a,
#cssmenu > ul > li > a:hover {
  color: rgb(30, 139, 164);
  background: rgb(240, 243, 243);
}
#cssmenu ul li.has-sub > a::after {
  position: absolute;
  right: 15px;
  display: block;
  width: 10px;
  height: 10px;
  content: "";
  border-radius: 2px;
}
#cssmenu > ul > li.has-sub > a::after {
  top: 14px;
  background: #666666;
}
#cssmenu > ul > li.has-sub:hover > a::after,
#cssmenu > ul > li.has-sub > a:hover::after {
  background: #222222;
}
#cssmenu ul ul li.has-sub > a::after {
  top: 13px;
  background: #ffffff;
}
#cssmenu ul ul li.has-sub:hover > a::after,
#cssmenu ul ul li.has-sub > a:hover::after {
  background: #dddddd;
}
#cssmenu ul li.has-sub > a::before {
  position: absolute;
  right: 15px;
  z-index: 2;
  display: block;
  width: 0;
  height: 0;
  border: 3px solid transparent;
  content: "";
}
#cssmenu > ul > li.has-sub > a::before {
  top: 16px;
  border-left-color: #ffffff;
}
#cssmenu ul ul li.has-sub > a::before {
  top: 15px;
  border-left-color: #2e353b;
}
#cssmenu ul {
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
#cssmenu ul ul {
  position: absolute;
  top: 0;
  left: -9999px;
  width: 180px;
  background: #2e353b;
  opacity: 0;
  -moz-transition: opacity 0.2s ease, -moz-transform 0.2s ease;
  -webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  -ms-transition: opacity 0.2s ease, -ms-transform 0.2s ease;
  -o-transition: opacity 0.2s ease, -o-transform 0.2s ease;
  transition: opacity .2s ease, transform .2s ease;
  -webkit-transform: rotate3d(0, 1, 0, 45deg);
  -moz-transform: rotate3d(0, 1, 0, 45deg);
  transform: rotate3d(0, 1, 0, 45deg);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
#cssmenu ul li:hover > ul {
  left: 100%;
  opacity: 1;
  transform: rotate3d(0, 0, 0, 0);
}
#cssmenu ul ul::after {
  position: absolute;
  left: -8px;
  top: 14px;
  z-index: 5;
  display: block;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-right-color: #2e353b;
  content: "";
}
#cssmenu ul ul a {
  padding: 12px 15px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  -webkit-transition: color .2s ease;
  -o-transition: color .2s ease;
  -ms-transition: color .2s ease;
  transition: color .2s ease;
}
#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #dddddd;
}

.results_typehead {
    position: absolute;
    width: 100%;
    z-index: 1000;
    top: 50px;
  }

.red {
  
  background-color: red;
}
.black {
   background-color : black;
}
.yellow {
    background-color:  yellow;
}
.green {
   background-color: green;
}
.orange {
    background-color: orange;
    color: black;
}
.pink {
  background-color: pink;
}
.purple {
  background-color: mediumpurple;
}

.column-alt-bgcolor{
  background: #f5f5f5;
}


.styled-select-div {
	margin-top: 10px;
}

.styled-select-label {
	padding-bottom: 0;
	
  font-weight: bold;
	color: #1e8aa3;
}

.disabled-input {
    color: #a6a6a6;
    background: #f5f5f5 !important;
}

.styled-select-select {
	width: 100%;
	height: 30px;
	overflow: hidden;   
	border: 1px solid #ccc;
}

.preSettlementServices .multiSelect button {
    min-height: 30px !important;
    padding: 0 6px 0 0;
    width: 100%;
    text-align: start;
    font-size: 13px;
    border-radius: 0;
    border: none;
}

.preSettlementServices .multiSelect .checkboxLayer {
    margin-left: 11px;
    min-width: 305px;
}

.preSettlementServices .multiSelect .buttonLabel {
    padding: 0 0 0 0;
}

.preSettlementServicesHiddenInput {
    display: none;
}

.main-am .sidebar, .main-am .main {
    background-color: #eae9f1;
}

.main-ch .sidebar {
    background-color: #1bbd1a;
}

.main-cg .submenu {
    background: #5fa8db;
}

.main-am .submenu {
    background: #625e9c;
}


.main-ch .submenu {
    background: #1bbd1a;
}

div[dropzone] {
    border: 2px dashed #bbb;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
    font: 20pt bold;
    color: #bbb;
    margin-bottom: 20px;
}

/* For Firefox */
.input[type='number'] {
    -moz-appearance: textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.container-row {
    padding: 10px 40px;    
}

.container-row-centered {
    padding: 10px 40px;
    justify-content: center;
}

.label-separator span + span:before {
    content: ' | ';
    padding: 0 15px;
}

.label-separator {
    letter-spacing: .15rem;
    color: rgb(30, 138, 163);
    font-weight: 700;
}

.container-row-centered .label-totals {
    letter-spacing: .02rem;
    vertical-align: middle;
    color: rgb(30, 138, 163);
    font-weight: bolder;
    font-size: larger;
    float: right;
    text-decoration:underline;
}

.container-row-centered .values-totals {
    font-weight: bold;
    padding-top: 11px;
    max-width:fit-content;
}

.container-row-centered .label-for {
    color: rgb(30, 138, 163);
    font-size: small;    
}

.container-row-centered .form-control {
    height:75%
}

.field-bordered {
    border: 1px solid #c9c9c9;
    border-radius: 8px;
}

.input-container {
    margin-bottom: 10px;
    min-width:20%;
}

.input-container input {
        border: none; 
        outline: none; 
        padding: 5px; 
    }

.padding-all-10-flex-end {
    padding: 10px;
    align-self: flex-end;
}

.padding-all-10-flex-end > button.button-margin-bottom {
        margin-bottom: 14px;
}

.padding-all-10 > .padding-all-10:not(:first-child) {
    margin-left: 10px;
}


.payee-header div.center-align,
.payee-items span.center-align,
.code-header div.center-align {
    text-align: center;
}

.payee-header,
.code-header {
    color: rgba(0, 0, 0, .6);
    font-weight: 700;
    font-size: 14px;
}

/* Payee accordion overrides */
uib-accordion.custom.payee-items .panel-group .panel-default .ch-panel-heading {
    padding: 0;
    min-height: 70px;
}

uib-accordion.custom.payee-items .panel-group .panel-default .ch-panel-heading .ch-panel-title > span {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, .7);
    font-weight: 400;
    height: 70px;
    line-height: 50px;
    padding: 10px 20px;
}

uib-accordion.custom.payee-items .panel-body .panel-body-items > span.data-items {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, .7);
    font-weight: 400;
    line-height: 43px;
}

uib-accordion.custom .panel-body .panel-body-items select {
    width: 95%;
}

uib-accordion.custom .panel-body .panel-body-items .select-readonly {
    padding-left: 5px;
}

uib-accordion.custom.payee-items span.button-padding,
uib-accordion.custom.payee-items span.search-item-padding{
    padding: 9px 0px;
}

.btn-remove-search-item {
    line-height: 10px;
}

uib-accordion.custom.payee-items .panel-group .card-divider {
    line-height: inherit;
    width: 60%;
}

uib-accordion.custom .ch-panel-title input.header-text-field,
uib-accordion.custom .panel-body input.body-text-field,
div.editable-table input.header-text-field {
    font-family: inherit;
    width: 95%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    padding: 7px 0;
    background-color: rgb(232, 240, 254);
}

div.editable-table span {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, .7);
    font-weight: 400;
    /*height: 70px;
    line-height: 50px;*/
    min-height: 45px;
    padding: 5px 5px;
}

uib-accordion.custom .panel-body .panel-body-title {
    background-color: rgb(245, 245, 245);
}

uib-accordion.custom .ch-panel-title input.header-text-field:focus,
uib-accordion.custom .panel-body input.body-text-field:focus,
div.editable-table input.header-text-field{
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
}

uib-accordion.custom.payee-items .panel-body {
    padding-top: 0;
}

.p\+ > h1.title-color {
    color: rgb(30, 138, 163);
}

.title-divider {
    line-height: 64px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-divider > span {
    margin-right: 5px;
}

.title-divider:after {
    background-color: #999999;
    height: 1px;
    flex: 1;
    content: '';
}

.title-divider-header {
    color: #999999;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.payee-address-title {
    background-color: rgb(245, 245, 245);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, .7);
    font-weight: 700;
    letter-spacing: 0.05em;
}

.payee-address-item {
    padding: 10px 0;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, .7);
    font-weight: 400;
    line-height: 34px;
}

.payee-address-scroll {
    overflow-y: auto;
    height: calc(100vh - 225px);
}

.payee-name-item {
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.btn-search {
    width: 82px;
}

span.form-control-feedback {
    position: absolute;
    top: 0;
    display: block;
    line-height: 34px;
}

span.pane {
    border: 3px solid #000000;
    background-color: #000000;
    color: #ffffff;
    font-weight: 800;
}
span.pane-grey {
    border-color: #eeeeee;
    background-color: #eeeeee;
}
span.pane-blue {
    border-color: #00B9E7;
    background-color: #00B9E7;
}
span.pane-green {
    border-color: #4CAF50;
    background-color: #4CAF50;
}
span.pane-red {
    border-color: #ED5565;
    background-color: #ED5565;
}

.fe-legend {
    text-align: right;
    list-style-type: none;
    font-size: 13px;
    margin-right: 3.75%;
}

.fe-legend span {
    vertical-align: middle;
}

.legend-details {
    font-size: 12px;
    text-align: right;
    margin-right: 3.75%;
}

.legend-box {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 7px;
}

.fe-purple { background-color: #a503fc; }
.fe-light-green { background-color: #97e3d5; }
.fe-yellow { background-color: #fceb03; }
.fe-orange { background-color: #fc6703; }
.fe-red { background-color: #fc2403; }