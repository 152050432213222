.angucomplete-dropdown {
    overflow-y: auto;
    max-height: 540px;
    line-height: 21px;
    font-size: 14px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    margin-top: 0;

    .angucomplete-row{
        color: $dulled_black;
    }
    .angucomplete-selected-row, .angucomplete-title:hover {
        background-color: #F0F0F0;
        opacity: .75;
    }
    .angucomplete-searching {
        min-height: 33px;
        line-height: 33px;
    }
    .angucomplete-title,
    .angucomplete-description {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

//fractal spinner, will be removed to separate directive
.fractal_spinner {
    background-image: url(icons/ametros_fractal.svg);
    background-repeat: no-repeat;
    background-position: 98% center;
    padding-right: 39px;
}