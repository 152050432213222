.account-details, .demographics, .contact, .referral-details, .fund, .beneficiary, .onboarding {
    label {
        margin-bottom: 0;
    }

    uib-accordion .panel-group {
        .card-input > input {
            width: 75%;
        }

        .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
            width: 75%;
        }
        angucomplete-alt {
            input {
                height: 32px;
                box-shadow: none;
            }
            .angucomplete-dropdown {
                width: $select-width-default;
            }
        }

    }

    .panel-body {
        padding-bottom: 64px;
    }

    .grid-component {
        &.ui-grid, .ui-grid-viewport {
            height: auto !important;
        }
    }
}

.profile-error {
    color: #1e8aa3;
    font-weight: bolder;
}

.deleteMember {
    background-color: #f5f5f5;
}

.assignToMe {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.contact, .beneficiary, .onboarding {
    .col-xs-12 {
        .card-divider {
            width: 97%;
        }
    }
}
