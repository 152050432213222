
.tooltipster-light {
    border-radius: 5px;
    border: 1px solid #00B9E7;
    background: #FBFBFC;
    color: #808285;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,185,231,0.9);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,185,231,0.9);
    box-shadow: 0px 0px 2px 0px rgba(0,185,231,0.9);
}

    .tooltipster-light .tooltipster-content {
        line-height: 16px;
        padding: 8px 10px;
        font-size: 12px;
        max-width: 450px;
    }
