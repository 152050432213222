$search_button_width: 48px;
.header {
    font-family: localSegoeUI, "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;

    .navbar.navbar-carehub {
        color: $navbar-default-brand-color;
        background-color: $header_background-color;
        max-height: $header_height;
        min-width: $min-page-width;
        line-height: 42px;

        .container-fluid {
            padding-right: 5px;
            padding-left: 5px;
        }

        .navbar-brand {
            height: $header_height;
            padding-left: 16px;
        }

        form {
            height: $search_form_height;
            input {
                border-radius: 0;
                font-size: 13px;
                font-style: italic;
                border-right-style: none;
                width: $search_form_width;
            }
            angucomplete-alt {
                .angucomplete-dropdown {
                    width: 500px;
                    margin-top: -2px;
                }
            }
            .search-button {
                width: $search_button_width;
                height: $search_form_height - 4px;
                background-color: #eaeaea;
                vertical-align: middle;
                margin-left: -4px;
                border-radius: 0;
                border: 1px solid rgb(204, 204, 204);
                .icon-ch-search {
                    transform: scale(0.6);
                    margin-top: 2px;
                    margin-left: -4px;
                }
            }
            .results_typehead {
                max-width: 650px;
            }
        }
    }

    .navbar-ch {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        .horizontal_separator {
            display: inline-block;
            vertical-align: middle;
            height: 24px;
            width: 2px;
            border: none;
            background-color: $dulled_white;
        }

        > li {
            display: inline-block;
            height: $header_height;
            vertical-align: middle;

            .new_referral {
                font-size: 18px;
                font-weight: bold;
                padding-left: 0;
            }

            & > a {
                color: $navbar-default-link-color;
                position: relative;
                display: block;
                padding: 10px 15px;
                text-decoration: none;
                .icon-ch-new_referral {
                    margin-top: 5px;
                    transform: scale(.5);
                }

                &:hover,
                &.active {
                    color: $dulled_white;
                    background-color: $header_background-color_dulled;
                }
            }
        }
    }

    @media screen and (max-width: 1800px) {
        .navbar.navbar-carehub {
            .container-fluid {
                padding-right: 0;
                padding-left: 0;
            }
            .navbar-brand {
                padding-left: 5px;
                padding-right: 5px;
                img {
                    transform: scale(.8);
                }
                max-width: 130px;
            }
            .navbar-form {
                padding-left: 0;
                padding-right: 0;
                input {
                    padding-left: 2px;
                    padding-right: 2px;
                    font-size: 10px;
                    width: 174px;
                }
                .search-button {
                    width: 34px;
                    .icon-ch-search {
                        margin-left: -12px;
                    }
                }
            }
        }

        .navbar-ch {
            > li {
                .new_referral {
                    font-size: 14px;
                }

                & > a {
                    padding: 10px 5px;
                    font-size: 12px;
                }
            }
        }
    }
}