.claims {
    font-family: localSegoeUI, "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
form[name="claimFilterForm"], .actions {
    .form-group {
        label {
            color: #1e8aa3;
            font-size: small;
            &.doubled {
                min-height: 38px;
                display: flex;
                align-items: flex-end;
            }
            &.oneLine{
                white-space:nowrap;
                text-overflow:ellipsis;
            }
        }
        input {
            height: 32px;
        }
        button {
            height: 32px;
            margin-top: 42px;
        }
        select {
            height: 32px;
            padding-left: 2px;
            padding-right: 3px;
        }
    }
}

.table.claim {
    .sticky-header {
        position: sticky;
        top: $header_height;
        z-index: 10;
        background-color: $leftSideBar-bgColor;
    }

    th, td {
        white-space: nowrap;
        border-bottom: 1px solid lightgray;
        border-left: 1px dotted lightgray;
    }

    & > thead > tr > th, & > tbody > tr > td {
        padding: 5px;
    }
}

.noResults {
    font-size: 20px;
}

.ch_spinner {
    background-image: url(icons/ametros_fractal.svg);
    background-repeat: no-repeat;
    background-position: center;
    min-height: 60px;
    background-size: contain;
    &-left {
        background-position: left;
    }
}

.bill-image {
    i {
        font-size: 20px;
        color: #C30102;
    }
}

.eor-image {
    i {
        font-size: 20px;
        color: #1E8AA3;
    }
}

.homepage-brand-image {
    /*background-color: $header_background-color;*/
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
}

.center-homepage-searchbox {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.member-homepage-searchbox {
    width: 260px;
    display: inline-block;
    margin-left: 30px;
    font-style: italic;
}

.search {
    .has-feedback .form-control-feedback {
        right: initial;
        left: 0 !important;
    }
    .has-feedback .form-control {
        padding-right: 12px;
        padding-left: 34px;
    }
    .angucomplete-dropdown {
        width: 100%;
    }
}

.filter-bubbles {
    & > :first-child {
        padding-left: 0;
    }
    .label {
        padding-left: .4em;
        padding-right: .3em;
        font-size: 11px;
    }
    .clear-icon {
        font-size: 14px;
        padding-left: 4px;
        padding-right: 3px;
        cursor: pointer;
    }
}


