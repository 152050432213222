table, th, td {
	border: none;
}

table {
	display: table;
}

table.bordered tr {
	border-bottom: 1px solid #d0d0d0;
}

table.striped tbody tr:nth-child(odd) {
	background-color: #f2f2f2;
}

table.hoverable tbody tr {
	-webkit-transition: background-color 0.25s ease;
	-moz-transition: background-color 0.25s ease;
	-o-transition: background-color 0.25s ease;
	-ms-transition: background-color 0.25s ease;
	transition: background-color 0.25s ease;
}

table.hoverable tbody tr:hover {
	background-color: #f2f2f2;
}

table.centered thead tr th,
table.centered tbody tr td {
	text-align: center;
}

table thead {
	border-bottom: 1px solid black;
	background-color: #f6f6f6;
}

table td, table th {
	padding: 5px;
	display: table-cell;
	text-align: left;
	vertical-align: middle;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background-clip: padding-box;
}

table th {
	padding: 10px 5px;
	color: rgba(0, 0, 0, 0.6);
}

table tfoot tr {
	color: rgba(0, 0, 0, 0.6);
	font-weight: bold;
}

@media only screen and (max-width : 992px) {
	table.responsive-table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		display: block;
		position: relative;
	}

	table.responsive-table th, table.responsive-table td {
		margin: 0;
		vertical-align: top;
	}

	table.responsive-table th {
		text-align: left;
	}

	table.responsive-table thead {
		display: block;
		float: left;
	}

	table.responsive-table thead tr {
		display: block;
		padding: 0 10px 0 0;
	}

	table.responsive-table tbody {
		display: block;
		width: auto;
		position: relative;
		overflow-x: auto;
		white-space: nowrap;
	}

	table.responsive-table tbody tr {
		display: inline-block;
		vertical-align: top;
	}

	table.responsive-table th {
		display: block;
		text-align: right;
	}

	table.responsive-table td {
		display: block;
		min-height: 1.25em;
		text-align: left;
	}

	table.responsive-table tr {
		padding: 0 10px;
	}

	table.responsive-table thead {
		border: 0;
		border-right: 1px solid #d0d0d0;
	}

	table.responsive-table.bordered th {
		border-bottom: 0;
		border-left: 0;
	}

	table.responsive-table.bordered td {
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
	}

	table.responsive-table.bordered tr {
		border: 0;
	}

	table.responsive-table.bordered tbody tr {
		border-right: 1px solid #d0d0d0;
	}
}

table.claims {
    td, th {
        white-space: nowrap;
        border-bottom: 1px solid lightgray;
        border-left: 1px dotted lightgray;
    }
}

table tr.tr-readonly td {
	color: #a6a6a6;
}