$trans-time: 300ms;
$ch-select-height: 27px;
$width: 100%;
$top-label-position: -16px;

.ch-input,
.ch-select {
    position: relative;
    margin: 10px 0 5px;
    border: none;

    input,
    textarea {
        background: none;
        color: $ch-input-color;
        font-size: 14px;
        padding: 7px 5px 3px;
        display: block;
        width: $width;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $ch-input-border-color;
        &:focus {
            outline: none;
        }
        &:focus ~ label,
        &.ng-valid-parse.flatpickr-input.ng-dirty ~ label,
        &.ng-valid-mask ~ label,
        &.not-empty ~ label {
            top: $top-label-position;
            font-size: 12px;
            color: $primary-color;
        }
        &:focus ~ .bar:before {
            width: $width;
        }

        &.ng-invalid.ng-touched {
            border-bottom: 2px solid $error-color;
        }
    }

    label {
        color: $ch-input-border-color;
        font-size: 14px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 5px;
        transition: $trans-time ease all;
    }

    .bar {
        position: relative;
        display: block;
        width: $width;
        &:before {
            content: '';
            height: 2px;
            width: 0;
            bottom: 0;
            position: absolute;
            background: $primary-color;
            transition: $trans-time ease all;
            left: 0;
        }
    }

    &--error {
        color: $error-color;
        font-size: 12px;
        text-align: right;
    }
}

.ch-radio {
    display: inline-block;
    position: relative;
    margin: 15px 0 0;
    font-size: 16px;
    line-height: 24px;

    &__input {
        position: absolute;
        top: 4px;
        left: 0;
        width: 36px;
        height: 20px;
        opacity: 0;
        z-index: 0;
    }

    // Unchecked
    &__label {
        display: block;
        padding: 0 0 0 24px;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            width: 12px;
            height: 12px;
            background-color: $ch-input-bg-color;
            border: 1px solid $primary-color;
            border-radius: 10px;
            z-index: 1;
            transition: border-color 0.28s cubic-bezier(.4, 0, .2, 1);
        }

        &:after {
            content: '';
            position: absolute;
            top: 10px;
            left: 3px;
            width: 6px;
            height: 6px;
            background-color: $primary-color;
            border-radius: 50%;
            z-index: 2;
            transform: scale(0, 0);
            transition: transform 0.28s cubic-bezier(.4, 0, .2, 1);
        }
    }

    // Checked
    &__input:checked + &__label {
        &:before {
            border-color: $primary-color;
        }

        &:after {
            transform: scale(1, 1);
        }
    }
}

div.ui-grid-cell input.ch-radio-as-checkbox__input{
    width: 120px;
    height: 20px;
}

.ch-radio-as-checkbox {
    cursor:pointer;

    &__input {
        opacity: 0;
        position: relative;
        z-index: 99;
    }

    &__label {
        font-weight: normal;
    }

    &__label > .ch-radio-as-checkbox__icon {
        display:inline-block;
        width: 16px;
        height: 20px;
        margin: -1px 0 0 -127px;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-image: url(icons/sprite.svg);
        background-position: -96px -144px;
        position: relative;
        transition: all 0.2s ease-out;
    }

    &__label > input:checked  + .ch-radio-as-checkbox__icon{
        background-position: -48px -144px;
        margin: -1px 0px 0 -127px;
        transition: all 0.2s ease-out;
    }

    &__title {
        display:inline-block;
        vertical-align: middle;
        position: relative;
    }
}

.ch-select{
    color: $ch-input-color;
    font-family: localSegoeUI, "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    border-bottom: 1px solid $ch-input-border-color;

    &--error {
        position: absolute;
        right: 0;
        color: $error-color;
        font-size: 12px;
        text-align: right;
    }

    label {
        color: $select-title-color;
    }

    .dropdown.open ~ label,
    .dropdown.not-empty ~ label {
        top: $top-label-position;
        font-size: 12px;
        color: $primary-color;
    }

    .dropdown.open ~ .bar:before {
        width: $width;
    }

    .bootstrap-select {
        .dropdown-menu > .active > a,
        .dropdown-menu > .active > a:hover,
        .dropdown-menu > .active > a:focus {
            background-color: $ch-input-bg-color;
            color: initial;
        }

        &.ng-invalid-required.ng-touched:not(.not-empty),
        &.ng-valid-required.ng-touched:not(.not-empty) {
            border-bottom: 2px solid $error-color;
            max-height: $ch-select-height;
        }

        &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
            width: 100%;
        }

        .filter-option-inner-inner {
            padding: 5px 5px 1px;
            text-overflow: ellipsis;
            margin-right: 15px;
        }

        .btn {
            text-transform: none;
            min-height: $ch-select-height;
        }

        .dropdown-menu {
            padding: 0;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 0;
            font-size: 14px;

            & > li > a {
                padding-left: 8px;
                padding-right: 16px;
            }
        }
    }
}