.invalid_page {
    display: flex;
    align-items: center;
    margin-top: 175px;
    font-family: Segoe UI;

    .message {
        width: 50%;
        padding-left: 214px;
        h2 {
            margin: 0 0 24px;
            font-weight: bold;
            font-size: 32px;
            line-height: 43px;
            color: $primary-color;
        }
        p {
            width: 610px;
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 32px;
            color: $error_gray;
        }
    }

    .error404 {
        width: 50%;
        min-height: 520px;
        padding-top: 125px;
        font-weight: bold;
        font-size: 174px;
        text-align: center;
        color: $error_gray;
        background: url(icons/static_fractal.svg) no-repeat center;
    }
}
