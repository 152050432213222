.page-divider {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
        margin-right: 8px;
    }
    &:after {
        background-color: $newreferral-line-color;
        height: 2px;
        flex: 1;
        content: '';
    }
    &-title {
        color: $primary-color;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 21px;
    }
}