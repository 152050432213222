#loading-bar {
    pointer-events: all;
    z-index: 99999;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: wait;
    position: fixed;
}