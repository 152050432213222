.claim-details {
    &--flags {
        td {
            background-color: $claimDetails-flags-color;
        }
    }

    &--wrap-id {
        padding: 1px;
    }

    &--content {
        display: flex;
        margin-bottom: 20px;

        fieldset {
            display: block;
            flex-grow: 1;
            flex-basis: 0;
            margin-inline-start: 2px;
            margin-inline-end: 2px;
            border: 1px solid $claimDetails-border-color;
            border-radius: 2px;
            padding-block-start: 0.35em;
            padding-inline-end: 0.75em;
            padding-block-end: 0.625em;
            padding-inline-start: 0.75em;
            min-inline-size: min-content;
        }

        legend {
            width: inherit;
            padding: 0 5px;
            border-bottom: none;
        }

        select {
            border-color: $claimDetails-border-color;
            height: 23px;
        }
    }

    &--tabs {
        margin-bottom: 20px;

        .nav {
            padding-left: 15px;
            cursor: initial;
            display: flex;
            align-items: center;
            border: none;

            a {
                border: none !important;
            }

            .active {
                .ng-binding {
                    color: #fff !important;
                    background-color: $primary-color;
                    font-size: 18px;
                    line-height: 24px;
                    border-radius: 2px;
                    padding: 7px 24px;

                    &:hover {
                        background-color: $primary-color !important;
                    }
                }
            }

            .ng-isolate-scope {
                .ng-binding {
                    padding: 7px 24px;
                    color: $leftSideBar-title-color;
                    font-size: 18px;
                    line-height: 24px;

                    &:hover {
                        background-color: rgba($primary-color, 0.2);
                        border-radius: 2px;
                    }
                }
            }
        }

        .ui-grid-header-cell-label {
            font-size: 14px;
        }

        .tab-content {
            border: none;
            padding: 15px;
            min-height: 250px;
            height: auto;
            display: flex;

            .grid-component {
                font-size: 14px;

                textarea {
                    width: 100%;
                    line-height: 1.5;
                }
            }
        }

        .tab-loading {
            width: 95vw;
        }
    }

    .grid-component {
        &.diagnoses, &.providers, &.notes, &.lineItems {
            height: auto !important;

            .ui-grid-viewport {
                height: auto !important;
            }
        }

        &.diagnoses {
            width: 1840px;

            .ui-grid-viewport {
                overflow: visible !important;
            }

            .angucomplete-dropdown {
                max-height: 200px;
            }
        }

        &.notes {
            .ui-grid-contents-wrapper {
                .ui-grid-header-cell .ui-grid-cell-contents {
                    height: 60px;
                }

                .ui-grid-row {
                    .ui-grid-cell {
                        .ui-grid-cell-contents {
                            height: 80px;
                            line-height: 28px;
                            width: 100%;

                            textarea:read-only {
                                background-color: rgb(235, 235, 228);
                            }
                        }
                    }
                }
            }
        }
    }

    &--lineItems {
        .panel-heading {
            min-height: 60px;
        }

        .grid-component {
            font-size: 14px;

            .angucomplete-dropdown {
                max-height: 185px;
            }

            .ui-grid {
                &-viewport {
                    overflow: visible !important;
                }
            }
        }
    }

    .claim-autocomplete {
        .angucomplete-dropdown {
            width: 100%;
        }
    }

    .w-81 {
        width: 81%;
    }

    .claimHierarchy {
        display: flex;
        justify-content: space-between;
        color: $claimDetails-hierarchy-text-color;
        background-color: $claimDetails-hierarchy-background-color;
        border: 2px solid $claimDetails-hierarchy-border-color;
        border-radius: 4px;
        font-size: 14px;
        min-width: 250px;
        padding: 6px 15px 6px 6px;

        &--text {
            font-size: 12px;
            text-transform: lowercase;
            font-weight: 300;
        }

        &--link {
            color: $claimDetails-hierarchy-link;
        }
    }

    .vendor-autocomplete {
        display: flex;
        align-items: center;
    }

    .vendor-autocomplete--input {
        width: 90%;
    }

    .vendor-autocomplete--holder {
        width: 100%;
    }

    .btn-remove {
        padding-left: 10px;
        font-size: 1rem;
        line-height: 30px;
    }

    .ui-grid-cell {
        display: flex;
        align-items: center;
    }

    .rule-results {
        display: flex;
        align-items: center;

        .rule-icons {
            font-weight: 100;
            font-size: 18px;
        }

        .rule-results_minus {
            &::before {
                display: block;
                content: "-";
                border-radius: 50%;
                border: 1px solid $claimDetails-icons-color;
                width: 18px;
                height: 18px;
                line-height: 9px;
                text-align: center;
                color: $claimDetails-icons-color;
                font-weight: normal;
            }
        }

        .rule-results_exclamation {
            padding-left: 7px;

            &::before {
                display: block;
                content: "!";
                border-radius: 50%;
                border: 1px solid $claimDetails-icons-color;
                width: 18px;
                height: 18px;
                line-height: 15px;
                text-align: center;
                color: $claimDetails-icons-color;
                font-weight: normal;
            }
        }
    }

    .indicator {
        width: 10px;
        height: 10px;
        background: red;
        border-radius: 5px;
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .grid-component .ui-grid-contents-wrapper .ui-grid-row .ui-grid-cell.ui-grid-row-rule-ispending {
        background-color: yellow
    }
}
