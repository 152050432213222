.grid-component2 {
    font-size: 12px;
    border: none;
    color: $claims-color;


    .lineHeight-48 {
        line-height: 48px;
    }

    .text-muted {
        color: $claims-color-muted;
    }

    .ui-grid-header {
        border: none;
        color: $claims-color;
        &-cell {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            border-right: none;
            border-left: none;
            border-top: 2px solid $primary-color;
            border-bottom: 1px solid;
            border-bottom-color: $leftSideBar-border-color;
            background-color: $claims-header-bgColor;
            &.text-capitalize {
                text-transform: capitalize !important;
            }
            div[role="columnheader"] {
                height: inherit;
                display: flex;
                align-items: center;
            }
            .ui-grid-cell-contents {
                display: inline-flex;
                align-items: center;
                max-width: 85%;
                .ui-grid-invisible {
                    display: none;
                }
            }
            &.narrow .ui-grid-cell-contents {
                max-width: 70%;
            }
        }
    }

    .ui-grid-top-panel {
        overflow: initial;
        margin-bottom: -1px;
        a {
            text-decoration: none;
            cursor: pointer;
            color: rgba(73, 75, 87, 0.75);

            .icon-ch-filter {
                position: absolute;
                bottom: 15px;
            }
        }
        .pagination {
            > .active > a,
            > .active > span {
                &,
                &:hover,
                &:focus {
                    color: $claims-color !important;
                    background-color: white;
                    border-color: white;
                    cursor: default;
                    text-decoration: underline;
                }
            }
            .claims-color {
                color: $claims-color;
            }
        }
        .ui-grid-header-viewport {
            overflow: initial;
        }
        .actions {
            .dropdown-menu {
                min-width: 390px;
                li {
                    padding-left: 0;
                    padding-right: 0;
                }
                .tab-content {
                    .tab-pane {
                        width: 100%;
                    }
                }
                .claims-actions--tabs {
                    padding: 5px;
                    .tab-content{
                        border-bottom: 1px solid #ddd;
                        border-left: 1px solid #ddd;
                        border-right: 1px solid #ddd;
                        padding: 15px;
                        min-height: 510px;
                        height: auto;
                        display: flex;
                        max-width: 365px;
                        .actions-container {
                            li > label {
                                font-size: 12px;
                                margin-bottom: 0;
                                input {
                                    margin: 3px 5px 0 0;
                                }
                            }
                        }
                    }
                }

            }
        }
        .dropdown-menu {
            min-width: 250px;
            padding-left: 5px;
            padding-right: 5px;
            label {
                display: inline-flex;
                align-items: end;
                font-size: 10px;
                font-weight: 500;
                text-transform: initial;
                input {
                    margin: 0 5px 0 0;
                }
            }
            li {
                padding-left: 15px;
                padding-right: 15px;
            }
            .search {
                input {
                    max-height: 21px;
                    font-size: 10px;
                }
                .has-feedback .form-control-feedback {
                    right: initial;
                    left: 0 !important;
                    line-height: 21px;
                    width: 21px;
                    height: 21px;
                }
                .has-feedback .form-control {
                    padding-left: 5px;
                }
            }
            .dropdown-toggle {
                color: rgba(73, 75, 87, 0.75);
            }
            .angucomplete-dropdown {
                font-weight: 500;
                font-size: 10px;
            }
            .dropdown-clearFilter {
                .dropdown-divider-title {
                    opacity: .5;
                    color: $claims-color;
                    text-transform: none;
                    line-height: initial;
                    padding: 5px 0;
                    cursor: default;
                    &.active {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }
        .dropdown-divider {
            height: auto;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            margin-bottom: 0;

            > span {
                margin-right: 5px;
            }
            &:after {
                background-color: $newreferral-line-color;
                height: 1px;
                flex: 1;
                content: '';
            }
            &-title {
                color: $newreferral-title-color;
                font-size: 10px;
                text-transform: uppercase;
            }
        }
        .dropdown-item {
            .input-group{
                .datePicker {
                    height: 21px;
                    font-size: 10px;
                    padding: 2px 3px 2px 3px;
                    line-height: normal;
                    background-color: $claims-bgColor;
                    .glyphicon {
                        font-size: 12px;
                    }
                    &.clear-icon {
                        font-size: 8px;
                        color: red;
                    }
                }
                input.datePicker {
                    color: $claims-color;
                }
            }
            input.amount {
                font-size: 12px;
            }
        }
        .sort-property {
            font-size: 10px;
            font-weight: 500;
            text-transform: none;
            padding-top: 5px;
        }
        .checkbox-container {
            max-height: 300px;
            overflow-y: auto;
            &-short{
                max-height: 155px;
                overflow-y: auto;
            }

            .dropdown-filter-item {
                text-transform: initial;
            }
        }
        .clear-filter-btn {
            margin-top: 29px;
        }
        .ui-grid-cell-contents ~ div {
            display: inline-flex;
            max-width: 20px;
        }
        .ui-grid-filter-container {
            max-width: 10px;
            padding: 4px 0 4px 0;
            .filtering {
                width: 100%;
                input {
                    min-width: 100%;
                    font-size: 10px;
                    height: 21px;
                    &:not(:valid) ~ .close-icon {
                        display: none;
                    }
                }
                .close-icon {
                    border:1px solid transparent;
                    background-color: transparent;
                    display: inline-block;
                    vertical-align: middle;
                    outline: 0;
                    padding: 0;
                    cursor: pointer;
                }
                .close-icon:after {
                    content: "X";
                    color: red;
                    display: block;
                    width: 13px;
                    height: 13px;
                    position: relative;
                    right: 21px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    padding: 2px;
                    font-weight: normal;
                    font-size: 8px;
                    cursor: pointer;
                }
            }
        }
    }

    .ui-grid-viewport {
        overflow-anchor: none;
    }

    .ui-grid-row:nth-child(even) .ui-grid-cell {
        background-color: $claims-grid-bgColor2;
    }

    .ui-grid-contents-wrapper {
        .ui-grid-header-cell .ui-grid-cell-contents {
            @include showCellContent();
        }

        .ui-grid-row {
            .ui-grid-cell {
                border-left: none;
                border-right: none;

                & .ui-grid-cell-contents {
                    input[type="checkbox"] {
                        margin: 0;
                    }
                    a {
                        color: initial;
                        text-decoration: none;
                    }
                    &.color-legend {
                        display: flex;
                        align-items: center;
                        div {
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            border-radius: 4px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                &-focus {
                    background-color: transparent;
                }
            }
        }
    }

    .ui-grid-pager-panel {
        abbr[title] {
            text-decoration: none;
            border-bottom: none;
        }
    }

    .noDataForGrid {
        @include vertical-align();
        opacity: 0.5;
        font-size: 40px;
        width: 100%;
        text-align: center;
    }
}