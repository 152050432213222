@font-face {
    font-family: 'Aller';
    src: url('fonts/Aller_Lt.ttf'),url('fonts/Aller_Bd.ttf'),url('fonts/Aller_Rg.ttf');
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('fonts/HelveticaNeueLTCom-Lt.ttf');
    src: url('fonts/HelveticaNeueLTStd-Lt.otf');
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('fonts/HelveticaNeueLTCom-Md.ttf');
    src: url('fonts/HelveticaNeueLTStd-Md.otf');
    font-weight: bold;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: localSegoeUI;
    src: local('Segoe UI'), local('SegoeUI');
}